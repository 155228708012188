<template>
    <DxTooltip
        :target="targetValue"
        :container="container"
        :position="placement"
        :visible="showValue"
        :disabled="disabled"
        :show-event="showEvent"
        :hide-event="hideEvent"
        :width="width"
        >
        <slot>{{title}}</slot>
    </DxTooltip>
</template>
<script>
    /*
        This is an initial placholder component to provide some functionality for existing tooltips.
        It will either be replaced by an official directive or expanded to be a replacement.
    */
    import { computed } from "vue";
    import { DxTooltip } from "devextreme-vue";

    export default {
        name: "RqTooltip",
        inheritAttrs: false,
        props: {
            boundary: [String,HTMLElement],
            container: { type: [String,HTMLElement], default: null },
            customClass: { type: String, default: "" },
            delay: { type: Number, default: 0 },
            placement: { type: String, default: "top" },
            target: { type: [String, HTMLElement, SVGSVGElement], required: true },
            title: { type: String, default: "" },
            trigger: { type: String, default: "hover" },
            show: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
            width: { type: [String, Number], default: "auto" },
        },
        components: { DxTooltip },
        setup(props, context) {
            const showValue = computed({
                get() { return props.show; },
                set(val) { context.emit("update:show", val); }
            });
            const targetValue = computed(() => {
                if(!_.isString(props.target) || _.startsWith(props.target, "#")) return props.target;
                return `#${props.target}`;
            });
            const showEvent = computed(() => {
                if(!_.includes(props.trigger, "hover")) return "";
                let name = "mouseenter";
                return props.delay > 0 ? { name, delay: props.delay } : name;
            });
            const hideEvent = computed(() => _.includes(props.trigger, "hover") ? "mouseleave click" : "");
            return {
                targetValue,
                showValue,
                showEvent,
                hideEvent
            };
        }
    };
</script>
