import RqClipboardManager from "./RqClipboardManager";
export default {
    install(app, options) {
        const clipboardManager = new RqClipboardManager(options);
        app.config.globalProperties.$rqClipboard = {
            isAvailable: clipboardManager.hasSecureContext,
            parseContent: (content, type) => clipboardManager.parseContent(content, type),
            copy: (content, type) => clipboardManager.execCopy(content, type),
            copyLastContent: () => clipboardManager.execAction("copy-last"),
            getLastContent: () => clipboardManager.lastCopiedContent,
            clearHistory: () => clipboardManager.execAction("clear-history")
        };
    }
};