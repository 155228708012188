import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faBars as fadBars,
    faDatabase as fadDatabase,
    faFileExport as fadFileExport,
    faFolderOpen as fadFolderOpen,
    faRepeat as fadRepeat,
    faSave as fadSave,
    faSpinner as fadSpinner
} from "@fortawesome/pro-duotone-svg-icons";

import {
    faClipboardListCheck as falClipboardListCheck,
    faEnvelope as falEnvelope,
    faFileAlt as falFileAlt,
    faFileInvoice as falFileInvoice,
    faFilterList as falFilterList,
    faQuestionCircle as falQuestionCircle,
    faSearch as falSearch,
    faShieldCheck as falShieldCheck,
    faTimes as falTimes
} from "@fortawesome/pro-light-svg-icons";

import {
    faBell as farBell,
    faBellOn as farBellOn,
    faBuilding as farBuilding,
    faCalendarAlt as farCalendarAlt,
    faCheckSquare as farCheckSquare,
    faChevronUp as farChevronUp,
    faChevronDown as farChevronDown,
    faChevronLeft as farChevronLeft,
    faChevronRight as farChevronRight,
    faCircle as farCircle,
    faCircleExclamation as farCircleExclamation,
    faCircleQuestion as farCircleQuestion,
    faClock as farClock,
    faCog as farCog,
    faDotCircle as farDotCircle,
    faFile as farFile,
    faFileArchive as farFileArchive,
    faFileCircleCheck as farFileCircleCheck,
    faFileCircleXmark as farFileCircleXmark,
    faFileCircleQuestion as farFileCircleQuestion,
    faFileCheck as farFileCheck,
    faFileCode as farFileCode,
    faFileExcel as farFileExcel,
    faFileImage as farFileImage,
    faFilePdf as farFilePdf,
    faFilePlus as farFilePlus,
    faFilePowerpoint as farFilePowerpoint,
    faFileText as farFileText,
    faFileWord as farFileWord,
    faFileLines as farFileLines,
    faFiles as farFiles,
    faInfoCircle as farInfoCircle,
    faHouse as farHouse,
    faListAlt as farListAlt,
    faListCheck as farListCheck,
    faMoneyCheckDollar as farMoneyCheckDollar,
    faRefresh as farRefresh,
    faRepeat as farRepeat,
    faSearch as farSearch,
    faSmile as farSmile,
    faSquare as farSquare,
    faTimes as farTimes,
    faTrashAlt as farTrashAlt,
    faUser as farUser,
    faGripDots as farGripDots
} from "@fortawesome/pro-regular-svg-icons";

import {
    faAdjust as fasAdjust,
    faAlignCenter as fasAlignCenter,
    faAlignLeft as fasAlignLeft,
    faAlignRight as fasAlignRight,
    faAngleLeft as fasAngleLeft,
    faAngleRight as fasAngleRight,
    faAngleUp as fasAngleUp,
    faAngleDown as fasAngleDown,
    faAngleDoubleLeft as fasAngleDoubleLeft,
    faAngleDoubleRight as fasAngleDoubleRight,
    faArrowLeft as fasArrowLeft,
    faArrowRight as fasArrowRight,
    faArrowUp as fasArrowUp,
    faArrowDown as fasArrowDown,
    faArrowAltSquareLeft as fasArrowAltSquareLeft,
    faArrowAltSquareRight as fasArrowAltSquareRight,
    faArrowRightArrowLeft as fasArrowRightArrowLeft,
    faAsterisk as fasAsterisk,
    faBalanceScale as fasBalanceScale,
    faBan as fasBan,
    faBarChart as fasBarChart,
    faBars as fasBars,
    faBold as fasBold,
    faBookmark as fasBookmark,
    faBrackets as fasBrackets,
    faBuilding as fasBuilding,
    faCalculator as fasCalculator,
    faCalendarAlt as fasCalendarAlt,
    faCaretUp as fasCaretUp,
    faCaretDown as fasCaretDown,
    faCaretLeft as fasCaretLeft,
    faCaretRight as fasCaretRight,
    faChartPie as fasChartPie,
    faCheck as fasCheck,
    faCheckCircle as fasCheckCircle,
    faCheckSquare as fasCheckSquare,
    faChevronCircleDown as fasChevronCircleDown,
    faChevronUp as fasChevronUp,
    faChevronDown as fasChevronDown,
    faChevronLeft as fasChevronLeft,
    faChevronRight as fasChevronRight,
    faCircle as fasCircle,
    faCircleInfo as fasCircleInfo,
    faCirclePlus as fasCirclePlus,
    faClock as fasClock,
    faCloudArrowDown as fasCloudArrowDown,
    faCloudArrowUp as fasCloudArrowUp,
    faCode as fasCode,
    faCog as fasCog,
    faCopy as fasCopy,
    faDesktop as fasDesktop,
    faDollarSign as fasDollarSign,
    faEdit as fasEdit,
    faCommentAltEdit as fasCommentAltEdit,
    faEnvelope as fasEnvelope,
    faExchange as fasExchange,
    faExclamationCircle as fasExclamationCircle,
    faExclamationTriangle as fasExclamationTriangle,
    faFileAlt as fasFileAlt,
    faFileChartPie as fasFileChartPie,
    faFileImport as fasFileImport,
    faFileInvoice as fasFileInvoice,
    faFileLines as fasFileLines,
    faFileUpload as fasFileUpload,
    faFilePlus as fasFilePlus,
    faFilter as fasFilter,
    faFolder as fasFolder,
    faFolderPlus as fasFolderPlus,
    faFolderOpen as fasFolderOpen,
    faGripLines as fasGripLines,
    faGripLinesVertical as fasGripLinesVertical,
    faGripVertical as fasGripVertical,
    faHashtag as fasHashtag,
    faHouseChimney as fasHouseChimney,
    faInfoCircle as fasInfoCircle,
    faItalic as fasItalic,
    faLambda as fasLambda,
    faLandmark as fasLandmark,
    faListCheck as fasListCheck,
    faListAlt as fasListAlt,
    faListTree as fasListTree,
    faListOl as fasListOl,
    faListUl as fasListUl,
    faLockAlt as fasLockAlt,
    faOmega as fasOmega,
    faMapLocationDot as fasMapLocationDot,
    faMinus as fasMinus,
    faMobileAlt as fasMobileAlt,
    faPlus as fasPlus,
    faPhoneAlt as fasPhoneAlt,
    faPrint as fasPrint,
    faRemove as fasRemove,
    faSave as fasSave,
    faSearch as fasSearch,
    faSignOutAlt as fasSignOutAlt,
    faSitemap as fasSitemap,
    faSpinner as fasSpinner,
    faStrikethrough as fasStrikethrough,
    faTasks as fasTasks,
    faThList as fasThList,
    faTimes as fasTimes,
    faTimesCircle as fasTimesCircle,
    faTools as fasTools,
    faTrash as fasTrash,
    faUnderline as fasUnderline,
    faUndo as fasUndo,
    faUpload as fasUpload,
    faUser as fasUser,
    faUserEdit as fasUserEdit,
    faUserPlus as fasUserPlus,
    faUserXmark as fasUserXmark,
    faXmark as fasXmark
} from "@fortawesome/pro-solid-svg-icons";

export const GlobalSymbolIcons = [
    "fad fa-bars",
    "fad fa-database",
    "fad fa-file-export",
    "fad fa-repeat",

    "fal fa-clipboard-list-check",
    "fal fa-envelope",
    "fal fa-file-alt",
    "fal fa-file-invoice",
    "fal fa-filter-list",
    "fal fa-shield-check",

    "far fa-bell",
    "far fa-building",
    "far fa-circle",
    "far fa-circle-info",
    "far fa-dot-circle",
    "far fa-exclamation-circle",
    "far fa-file-archive",
    "far fa-file-check",
    "far fa-file-code",
    "far fa-file-excel",
    "far fa-file-image",
    "far fa-file-pdf",
    "far fa-file-powerpoint",
    "far fa-file-text",
    "far fa-file-word",
    "far fa-file-lines",
    "far fa-files",
    "far fa-house",
    "far fa-info-circle",
    "far fa-list-alt",
    "far fa-list-check",
    "far fa-question-circle",

    "fas fa-arrow-alt-square-left",
    "fas fa-arrow-alt-square-right",
    "fas fa-arrow-right-arrow-left",
    "fas fa-asterisk",
    "fas fa-bars",
    "fas fa-caret-up",
    "fas fa-caret-down",
    "fas fa-caret-left",
    "fas fa-caret-right",
    "fas fa-calendar-alt",
    "fas fa-check",
    "fas fa-clock",
    "fas fa-cloud-arrow-down",
    "fas fa-cloud-arrow-up",
    "fas fa-circle",
    "fas fa-check-circle",
    "fas fa-check-square",
    "fas fa-chevron-circle-down",
    "fas fa-edit",
    "fas fa-exclamation-triangle",
    "fas fa-file-lines",
    "fas fa-filter",
    "fas fa-folder",
    "fas fa-folder-open",
    "fas fa-info-circle",
    "fas fa-list-check",
    "fas fa-list-tree",
    "fas fa-search",
    "fas fa-omega",
    "fas fa-lambda",
    "fas fa-lock-alt",
    "fas fa-file-invoice",
    "fas fa-hashtag",
    "fas fa-map-location-dot",
    "fas fa-mobile-alt",
    "fas fa-phone-alt"
];

export const createFaLibraryRestricted = () => library.add(
    //regular
    farCircleExclamation,

    //solid
    fasArrowLeft,
    fasUserXmark
);

export const createFaLibrary = () => library.add(
    //duotone
    fadBars,
    fadDatabase,
    fadFileExport,
    fadFolderOpen,
    fadRepeat,
    fadSave,
    fadSpinner,

    //light
    falClipboardListCheck,
    falFileAlt,
    falEnvelope,
    falFileInvoice,
    falFilterList,
    falQuestionCircle,
    falSearch,
    falShieldCheck,
    falTimes,

    //regular
    farBell,
    farBellOn,
    farBuilding,
    farCalendarAlt,
    fasChevronCircleDown,
    farCheckSquare,
    farChevronUp,
    farChevronDown,
    farChevronLeft,
    farChevronRight,
    farCircle,
    fasCircleInfo,
    farCircleExclamation,
    farCircleQuestion,
    farClock,
    farCog,
    farDotCircle,
    farFile,
    farFileArchive,
    farFileCircleCheck,
    farFileCircleXmark,
    farFileCircleQuestion,
    farFileCheck,
    farFileCode,
    farFileExcel,
    farFileImage,
    farFilePdf,
    farFilePlus,
    farFilePowerpoint,
    farFileText,
    farFileWord,
    farFileLines,
    farFiles,
    farHouse,
    farInfoCircle,
    farListAlt,
    farListCheck,
    farMoneyCheckDollar,
    farRefresh,
    farRepeat,
    farSearch,
    farSmile,
    farSquare,
    farTimes,
    farTrashAlt,
    farUser,
    farGripDots,

    //solid
    fasAdjust,
    fasAlignCenter,
    fasAlignLeft,
    fasAlignRight,
    fasAngleLeft,
    fasAngleRight,
    fasAngleUp,
    fasAngleDown,
    fasAngleDoubleLeft,
    fasAngleDoubleRight,
    fasArrowRight,
    fasArrowLeft,
    fasArrowUp,
    fasArrowDown,
    fasArrowAltSquareLeft,
    fasArrowAltSquareRight,
    fasArrowRightArrowLeft,
    fasAsterisk,
    fasBalanceScale,
    fasBan,
    fasBarChart,
    fasBars,
    fasBold,
    fasBookmark,
    fasBrackets,
    fasBuilding,
    fasCalculator,
    fasCalendarAlt,
    fasCaretDown,
    fasCaretRight,
    fasCaretLeft,
    fasCaretUp,
    fasChevronDown,
    fasChevronUp,
    fasChevronRight,
    fasChevronLeft,
    fasChartPie,
    fasCheck,
    fasCheckCircle,
    fasCheckSquare,
    fasCircle,
    fasCirclePlus,
    fasClock,
    fasCloudArrowDown,
    fasCloudArrowUp,
    fasCode,
    fasCog,
    fasCopy,
    fasDesktop,
    fasDollarSign,
    fasEdit,
    fasCommentAltEdit,
    fasEnvelope,
    fasExchange,
    fasExclamationCircle,
    fasExclamationTriangle,
    fasFileAlt,
    fasFileChartPie,
    fasFileImport,
    fasFileInvoice,
    fasFileLines,
    fasFilePlus,
    fasFileUpload,
    fasFilter,
    fasFolder,
    fasFolderPlus,
    fasFolderOpen,
    fasGripLines,
    fasGripLinesVertical,
    fasGripVertical,
    fasHashtag,
    fasHouseChimney,
    fasInfoCircle,
    fasItalic,
    fasLambda,
    fasLandmark,
    fasListAlt,
    fasListCheck,
    fasListTree,
    fasListOl,
    fasListUl,
    fasLockAlt,
    fasMapLocationDot,
    fasMinus,
    fasMobileAlt,
    fasOmega,
    fasPlus,
    fasPhoneAlt,
    fasPrint,
    fasRemove,
    fasSave,
    fasSearch,
    fasSignOutAlt,
    fasSitemap,
    fasSpinner,
    fasStrikethrough,
    fasTasks,
    fasThList,
    fasTimes,
    fasTimesCircle,
    fasTools,
    fasTrash,
    fasUnderline,
    fasUndo,
    fasUpload,
    fasUser,
    fasUserEdit,
    fasUserPlus,
    fasUserXmark,
    fasXmark
);