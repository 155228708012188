<template>
    <transition name="simple-fade">
        <rq-loading-indicator
            v-if="isBusy"
            :message="busyMessage"
            visible
            in-rq-busy
        />
    </transition>
</template>

<script>
    import { mapGetters } from "vuex";
    import { onBeforeUnmount } from "vue";
    import { useRqBusy } from "@/shared/plugins/rqBusy";
    import { GlobalEventManager } from "@/app.events";

    export default {
        name: "RqBusyManager",
        setup() {
            const { startWait, endWait } = useRqBusy();
            const sourceKey = "RqBusyManager";
            GlobalEventManager.onBusyStart(sourceKey, startWait);
            GlobalEventManager.onBusyEnd(sourceKey, endWait);

            onBeforeUnmount(() => {
                GlobalEventManager.unregister(sourceKey);
            });
        },
        computed: {
            ...mapGetters([
                "isBusy",
                "busyMessage"
            ])
        }
    }
</script>
