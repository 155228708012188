export class ConfigurationName {
    static get EsignAllowPreDrawnSignature () { return 'UsePredefinedSignature'; }
}

export class RoleType {
    static get None () {return 0;}
    static get Bank () {return 1;}
    static get TitleCompany () {return 2;}
    static get Underwriter () {return 3;}
    static get Attorney () {return 4;}
    static get HomeInspection () {return 5;}
    static get Lender () {return 6;}
    static get Member () {return 7;}
    static get PestInspection () {return 8;}
    static get PlaceOfClosing () {return 9;}
    static get Realtor () {return 10;}
    static get Surveyor () {return 11;}
    static get SearchProvider () {return 12;}
    static get RecordingOffice () {return 13;}
    static get MortgageBroker () {return 14;}
    static get Buyer () {return 15;}
    static get Seller () {return 16;}
    static get Notary () {return 17;}
    static get LegalPaperworkPreparedBy () {return 18;}
    static get Referral () {return 19;}
    static get AdditionalBuyerRealtor () {return 20;}
    static get AdditionalBuyerAttorney () {return 21;}
    static get AdditionalLender () {return 22;}
    static get AdditionalTitleCompany () {return 23;}
    static get Customer () {return 24;}
    static get AdditionalSellerRealtor () {return 25;}
    static get AdditionalSellerAttorney () {return 26;}
    static get SettlementAgent () {return 28;}
}

export class UploadCategory {
    static get TitleProduction () {return 100;}
    static get OwnerPolicy () {return 200;}
    static get LoanPolicy () {return 300;}
}

export class ContentDataFormat {
    static get UnencodedString () {return 1;}
    static get Base64String () {return 2;}
    static get Uint8Array () {return 3;} // binary
}

const pcAccessOptions = {
    Default: 2,
    Access: 1,
    NoAccess: 0
};
export class PCAccessOptions {
    static get Default () {return pcAccessOptions.Default;}
    static get Access () {return pcAccessOptions.Access;}
    static get NoAccess () {return pcAccessOptions.NoAccess;}

    static get lookupItems () {
        return _.map(pcAccessOptions, a => {return { id: a, name: PCAccessOptions.displayValue(a) };});
    }

    static get lookupItemsNoDefault () {
        const result = PCAccessOptions.lookupItems;
        _.pullAt(result, [0]);
        return result;
    }

    static displayValue (id) {
        switch (id) {
            case pcAccessOptions.Default: return "Use Role Default";
            case pcAccessOptions.Access: return "Access";
            case pcAccessOptions.NoAccess: return "No Access";
        }
        return "";
    }
}

const userScreenAccessLevel = {
    None: 0,
    Read: 1,
    Full: 2
};

export class UserScreenAccessLevel {
    static get None () {return userScreenAccessLevel.None;}
    static get Read () {return userScreenAccessLevel.Read;}
    static get Full () {return userScreenAccessLevel.Full;}

    static get lookupItems () {
        return _.map(userScreenAccessLevel, a => {return { id: a, name: UserScreenAccessLevel.displayValue(a) };});
    }

    static displayValue (id) {
        switch (id) {
            case userScreenAccessLevel.None:
                return "None";
            case userScreenAccessLevel.Read:
                return "Read Only";
            case userScreenAccessLevel.Full:
                return "Full Access";
        }
        return "";
    }
}

const userAccessLevel = {
    None: 0,
    CreatedOnly: 1,
    Any: 2
};
export class UserAccessLevel {
    static get None () {return userAccessLevel.None;}
    static get CreatedOnly () {return userAccessLevel.CreatedOnly;}
    static get Any () {return userAccessLevel.Any;}

    static get lookupItems () {
        return _.map(userAccessLevel, a => {return { id: a, name: UserAccessLevel.displayValue(a) };});
    }

    static displayValue (id) {
        switch (id) {
            case userAccessLevel.None:
                return "None";
            case userAccessLevel.CreatedOnly:
                return "Only When Created/Owned by User";
            case userAccessLevel.Any:
                return "Any";
        }
        return "";
    }
}

const orderService = {
    ALTAOwner: 0,
    ALTATitleGuard: 1,
    ALTAMortgage: 2,
    ALTASimultaneous: 3,
    ALTAConstructionLoan: 4,
    ALTAOther: 5,
    TXOwnerResident: 6,
    TXOwnerNonResident: 7,
    TXMortgage: 8,
    TXSimultaneousResident: 9,
    TXSimultaneousNonResident: 10,
    TXBinder: 11,
    TXOther: 12
};

export class OrderServiceType {
    static get AltaOwner () {return orderService.ALTAOwner;}
    static get AltaTitleGuard () {return orderService.ALTATitleGuard;}
    static get AltaMortgage () {return orderService.ALTAMortgage;}
    static get AltaSimultaneous () {return orderService.ALTASimultaneous;}
    static get AltaConstructionLoan () {return orderService.ALTAConstructionLoan;}
    static get AltaOther () {return orderService.ALTAOther;}
    static get TxOwnerResident () {return orderService.TXOwnerResident;}
    static get TxOwnerNonResident () {return orderService.TXOwnerNonResident;}
    static get TxMortgage () {return orderService.TXMortgage;}
    static get TxSimultaneousResident () {return orderService.TXSimultaneousResident;}
    static get TxSimultaneousNonResident () {return orderService.TXSimultaneousNonResident;}
    static get TxBinder () {return orderService.TXBinder;}
    static get TxOther () {return orderService.TXOther;}

    static get ownerSpecificTypes() {
        return [
            orderService.ALTAOwner,
            orderService.ALTATitleGuard,
            orderService.TXOwnerResident,
            orderService.TXOwnerNonResident
        ];
    }

    static get loanSpecificTypes() {
        return [
            orderService.ALTAMortgage,
            orderService.ALTAConstructionLoan,
            orderService.TXMortgage,
        ];
    }

    static get simultaneousEligibleTypes() {
        return [
            orderService.ALTAOther,
            orderService.ALTASimultaneous,
            orderService.ALTATitleGuard,
            orderService.TXBinder,
            orderService.TXOther,
            orderService.TXSimultaneousResident,
            orderService.TXSimultaneousNonResident
        ];
    }

    static displayValue (id) {
        switch (id) {
            case 0:
                return "Owner";
            case 1:
                return "Title Guarantee";
            case 2:
                return "Loan";
            case 3:
                return "Simultaneous";
            case 4:
                return "Const Loan";
            case 5:
                return "Other";
            case 6:
                return "Owner (Res)";
            case 7:
                return "Owner (Non Res)";
            case 8:
                return "Loan";
            case 9:
                return "Simult. (Res)";
            case 10:
                return "Simult. (Non Res)";
            case 11:
                return "Binder";
            case 12:
                return "Other";
        }
        return "Unknown";
    }
}

const numberFormat = {
    None: 0,
    ArabicNumbers: 3,
    Letters: 4,
    CapitalLetters: 5,
    RomanNumbers: 6,
    SmallRomanNumbers: 7
};

export class NumberFormat {
    static get None () {return numberFormat.None;}
    static get ArabicNumbers () {return numberFormat.ArabicNumbers;}
    static get Letters () {return numberFormat.Letters;}
    static get CapitalLetters () {return numberFormat.CapitalLetters;}
    static get RomanNumbers () {return numberFormat.RomanNumbers;}
    static get SmallRomanNumbers () {return numberFormat.SmallRomanNumbers;}

    static displayValue (id) {
        switch (id) {
            case numberFormat.ArabicNumbers: return "1,2,3...";
            case numberFormat.Letters: return "a,b,c...";
            case numberFormat.CapitalLetters: return "A,B,C...";
            case numberFormat.RomanNumbers: return "I,II,III...";
            case numberFormat.SmallRomanNumbers: return "i,ii,iii...";
        }
        return "None";
    }

    static get lookupItems () {
        return _.map(numberFormat, nf => {return { id: nf, name: NumberFormat.displayValue(nf) };});
    }
}

const textControlListType = {
    None: 1,
    Bulleted: 2,
    Numbered: 4,
    Structured: 8
};
export class TextControlListType {
    static get None () {return textControlListType.All;}
    static get Bulleted () {return textControlListType.Bulleted;}
    static get Numbered () {return textControlListType.Numbered;}
    static get Structured () {return textControlListType.Structured;}

    static displayValue (id) {
        switch (id) {
            case textControlListType.None:
                return "None";
            case textControlListType.Bulleted:
                return "Bulleted";
            case textControlListType.Numbered:
                return "Numbered";
            case textControlListType.Structured:
                return "Structured";
        }
        return "";
    }

    static displayIcon (id) {
        switch (id) {
            case textControlListType.Bulleted:
                return "fa fa-list-ul";
            case textControlListType.Numbered:
                return "fa fa-list-ol";
            case textControlListType.Structured:
                return "fa fa-th-list";
        }
        return "";
    }

    static get lookupItems () {
        return _.map(textControlListType, t => {return { id: t, name: TextControlListType.displayValue(t) };});
    }
}

const listFormatCharacter = {
    None: 0,
    Tab: 9,
    Space: 32
};
export class ListFormatCharacter {
    static get None () {return listFormatCharacter.None;}
    static get Tab () {return listFormatCharacter.Tab;}
    static get Space () {return listFormatCharacter.Space;}

    static get lookupItems () {
        return _.map(listFormatCharacter, c => {return { id: c, name: ListFormatCharacter.displayValue(c) };});
    }

    static displayValue (id) {
        switch (id) {
            case listFormatCharacter.Tab:
                return "Tab";
            case listFormatCharacter.Space:
                return "Space";
        }
        return "None";
    }
}

const editPolicyNumberEnum = {
    CanEditPriorToLockdown : 1, 
    CannotEdit : 0,
    NoRestriction : 2,
};

export class EditPolicyNumber {
    static get CanEditPriorToLockdown () {return editPolicyNumberEnum.CanEditPriorToLockdown;}
    static get CannotEdit () {return editPolicyNumberEnum.CannotEdit;}
    static get NoRestriction () {return editPolicyNumberEnum.NoRestriction;}
}

const createPolicyNumberEnum  = {
    CanManuallyEnterValidated : 2, 
    CannotGenerate : 0,
    MustGenerate : 1,
    NoRestriction : 3,
};

export class CreatePolicyNumber {
    static get CanManuallyEnterValidated () {return createPolicyNumberEnum.CanManuallyEnterValidated;}
    static get CannotGenerate () {return createPolicyNumberEnum.CannotGenerate;}
    static get MustGenerate () {return createPolicyNumberEnum.MustGenerate;}
    static get NoRestriction () {return createPolicyNumberEnum.NoRestriction;}
}

const auditChangeType  = {
    Add : 0, 
    Edit : 1,
    Delete : 2,
    Label : 3,
};

export class AuditChangeType {
    static get Add () {return auditChangeType.Add;}
    static get Edit () {return auditChangeType.Edit;}
    static get Delete () {return auditChangeType.Delete;}
    static get Label () {return auditChangeType.Label;}
}

const orderNoteCategory  = {    
    Order: 0,
    VendService: 1,
    RecordingInstrument: 2,
    TitleProduction: 3,
    CheckWriting: 4,
    Policy: 5,    
    SettlementStatementHUD: 6,
    CSS: 7,
    Invoice: 8
};

export class OrderNoteCategory {
    static get Order () {return orderNoteCategory.Order;}
    static get VendService () {return orderNoteCategory.VendService;}
    static get RecordingInstrument () {return orderNoteCategory.RecordingInstrument;}
    static get TitleProduction () {return orderNoteCategory.TitleProduction;}
    static get CheckWriting () {return orderNoteCategory.CheckWriting;}
    static get Policy () {return orderNoteCategory.Policy;}
    static get SettlementStatementHUD () {return orderNoteCategory.SettlementStatementHUD;}
    static get CSS () {return orderNoteCategory.CSS;}
    static get Invoice () {return orderNoteCategory.Invoice;}

    static displayValue (id) {
        switch (id) {
            case orderNoteCategory.Order: return "Order";
            case orderNoteCategory.VendService: return "Vendor Service";
            case orderNoteCategory.RecordingInstrument: return "Recording Instrument";
            case orderNoteCategory.TitleProduction: return "Title Production";
            case orderNoteCategory.CheckWriting: return "Check Writing";
            case orderNoteCategory.Policy: return "Policy";
            case orderNoteCategory.SettlementStatementHUD: return "Settlement Statement (HUD)";
            case orderNoteCategory.CSS: return "CSS";
            case orderNoteCategory.Invoice: return "Invoice";
        }
        return "";
    }
}

const orderLockOption  = {
    All: 0, 
    LockOnly: 1,
    UnlockOnly: 2,
    None: 3
};

export class OrderLockOption {
    static get All () {return orderLockOption.All;}
    static get LockOnly () {return orderLockOption.LockOnly;}
    static get UnlockOnly () {return orderLockOption.UnlockOnly;}
    static get None () {return orderLockOption.None;}
}

const escrowLockOption  = {
    All: 0, 
    LockOnly: 1,
    UnlockOnly: 2,
    None: 3
};

export class EscrowLockOption {
    static get All () {return escrowLockOption.All;}
    static get LockOnly () {return escrowLockOption.LockOnly;}
    static get UnlockOnly () {return escrowLockOption.UnlockOnly;}
    static get None () {return escrowLockOption.None;}
}

export class OrderStatus {
    static get New () {return 0;}
    static get Open () {return 1;}
    static get Closed () {return 2;}
    static get Cancelled () { return 3; }
    static get Template () { return 4; }
    static get Old () { return 5; }
    static get PaperlessCloser () { return 6;}
    static get OnHold () { return 7;}
    static get Legacy () { return 8; }
}


const phoneType = {
    Home: 0,
    Work: 1,
    Mobile: 2,
};

export class PhoneTypes {
    static get Home () {
        return phoneType.Home;
    }
    static get Work () {
        return phoneType.Work;
    }
    static get Mobile () {
        return phoneType.Mobile;
    }

    static displayValue (id) {
        switch (id) {
            case phoneType.Home: return "Home";
            case phoneType.Work: return "Work";
            case phoneType.Mobile: return "Mobile";
        }
        return "";
    }
}

export class AlertSeverity {
    static get Info() { return 0; }
    static get Warning() { return 1; }
    static get Error() { return 2; }
}