class Interval {
    constructor() {
        this.currentIntervals = [];
    }

    make(name, callback, intervalMs, callOnStart=false, delayMs=100, ...args) {
        const self = this;

        if (!_.isFunction(callback)) return Promise.resolve();

        let delay = _.parseNumber(delayMs, 100);

        return _.wait(delay)
            .then(() => {
                self.clear(name);
                if (_.parseBool(callOnStart)) callback(...args);
            })
            .then(() => {
                let newInterval = setInterval(callback, intervalMs, ...args);
                self.currentIntervals.push({ name, interval: newInterval });
            });
    }

    // clear a single interval
    clear(name) {
        let target = _.find(this.currentIntervals, { name });
        if(!target) return;

        clearInterval(target.interval);

        _.remove(this.currentIntervals, { name });
    }

    // clear all intervals
    clearAll() {
        while (this.currentIntervals.length > 0) {
            this.clear(this.intervals[0].name);
        }
    }
}

let interval = new Interval();

export default interval;