<template>
    <div :class="classAttr">
        <label v-if="label">{{label}}</label>
        <b-btn
            v-for="item in items"
            :key="`${editorId}-${item.name}`"
            variant="icon"
            :class="itemClassAttr"
            :disabled="readOnly || item.disabled"
            @click="onToolbarItemClick(item)">
            <FontAwesomeIcon :icon="item.icon" />
        </b-btn>
    </div>
</template>
<script>
    export default {
        name: "RqHtmlEditorToolbar",

        props: {
            editorId: { type: String, default: "global" },
            label: { type: String, default: "" },
            itemClass: { type: String, default: "" },
            borderless: { type: Boolean, default: false },
            disabledItems: { type: Array, default: () => [] },
            hiddenItems: { type: Array, default: () => [] },
            readOnly: { type: Boolean, default: false }
        },

        computed: {
            classAttr() {
                return {
                    "rq-html-editor-toolbar": true,
                    "rq-borderlesss": this.borderless
                };
            },
            itemClassAttr() {
                return {
                    "rq-html-editor-toolbar-button": true,
                    [this.itemClass]: !_.isEmpty(this.itemClass)
                }
            },
            items() {
                let result = [
                    { name: "bold", icon: "fas fa-bold", command: "bold" },
                    { name: "italic", icon: "fas fa-italic", command: "bold" },
                    { name: "underline", icon: "fas fa-underline", command: "bold" },
                    { name: "alignLeft", icon: "fas fa-align-left", command: "bold" },
                    { name: "alignCenter", icon: "fas fa-align-center", command: "bold" },
                    { name: "alignRight", icon: "fas fa-align-right", command: "bold" },
                ];
                if(_.isEmpty(this.hiddenItems)) return result;
                return _.filter(result, item => !_.includes(this.hiddenItems, item.name));
            }
        },

        methods: {
            onToolbarItemClick(item) {
                this.execEditorCommand(item.command);
            },
            execEditorCommand(command) {
                _.invoke(window, "tinyMCE.activeEditor.execCommand", command);
            }
        }

    };
</script>