<template>
    <IssueDisplay
        icon="fas fa-user-xmark"
        title="ready2close Tenant Access Denied">
        <template #message>
            Your ready2close user account does not have access to this tenant.<br />
            Please contact your admin to create your account.
        </template>
        <template #default>
            <button
                type="button"
                class="btn btn-back btn-sm mt-4"
                @click="backToLogin">
                <FontAwesomeIcon icon="fas fa-arrow-left" class="me-2" />Back to Login
            </button>
        </template>
    </IssueDisplay>
</template>
<script setup>
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
    import IssueDisplay from "./IssueDisplay.vue";
    import Identity from "@/shared/services/identity.service";
    const backToLogin = () => Identity.logOut();
</script>