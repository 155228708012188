import { createStore } from "vuex";
import * as getters from "./getters";
import { START_WAIT, END_WAIT, END_ALL_WAITS } from "@/store/actions";
import { SET_BUSY_QUEUE, SET_BUSY_MESSAGE, SET_ERROR, SET_SAVED_ROUTE } from '@/store/mutations';
import modules from "./modules";

const debug = process.env.NODE_ENV !== "production";
const getDefaultBusyMessage = () => ({
    key: null,
    message: ""
});
const getDefaultState = () => ({
    error: null,
    lastPage: null,
    savedRoute: null,
    isSupportedDevice: null,
    busyQueue: [],
    busyMessage: getDefaultBusyMessage()
});

const store = createStore({
    state: getDefaultState(),
    mutations: {
        [SET_ERROR] (state, value) { state.error = value; },
        [SET_SAVED_ROUTE] (state, value) { state.savedRoute = value; },
        [SET_BUSY_QUEUE] (state, value=[]) { state.busyQueue = value; },
        [SET_BUSY_MESSAGE] (state, value=null) {
            let messageObj = getDefaultBusyMessage();
            if(_.isString(value))
                messageObj.message = value;
            else if(_.isObject(value))
                messageObj = { key: null, message: null, ...value };
            state.busyMessage = messageObj;
        }
    },
    getters: {
        ...getters,
        isBusy: state => !_.isEmpty(state.busyQueue),
        busyMessage: state => {
            let { key, message } = state?.busyMessage || null;
            if(_.isEmpty(key) || _.isEmpty(message) || !_.includes(state.busyQueue, key)) return "";
            return message;
        }
    },
    actions: {
        [START_WAIT]({ commit, state }, key) {
            let queue = _.clone(state?.busyQueue);
            if(_.includes(queue, key)) return;
            queue.push(key);
            return commit(SET_BUSY_QUEUE, queue);
        },
        [END_WAIT]({ commit, state }, key) {
            let queue = _.clone(state?.busyQueue);
            if(!_.includes(queue, key)) return;
            _.pull(queue, key);
            // if(!_.isNil(key) && state?.busyMessage?.key === key)
            //     commit(SET_BUSY_MESSAGE);
            return commit(SET_BUSY_QUEUE, queue);
        },
        [END_ALL_WAITS]({ commit, state }) {
            if(_.isEmpty(state?.busyQueue)) return;
            return commit(SET_BUSY_QUEUE);
        }
    },
    modules,
    strict: debug
});

export default store;