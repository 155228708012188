import Store from '@/store';
import { DateTime } from "luxon";
import { TimeZoneInfo } from "./Timezones";

const momentFormat = 'YYYY-MM-DDTHH:mm:ss';

const DateTimeHelper = {

    timeZoneConvert (config) {

        let cfg = _.defaultsDeep({},
            config,
            {
                dateTime: null,
                sourceTimeZone: "UTC",
                destinationTimeZone: Store.getters.clientTimeZone,
            });

        let dateTime = _.get(cfg, 'dateTime', null);

        if (!dateTime) return null;

        let dateTimeValue;

        if (_.isDate(dateTime)) {
            // This is needed since momentjs, if instantiated with a date object,
            // ignores the timezone we provide (for some reason).
            // This effectively reads the value AS IS and gives a flat textual representation.
            dateTimeValue = moment(dateTime).format(momentFormat);
        }
        else {
            dateTimeValue = dateTime;
        }

        let sourceValue = moment.tz(dateTimeValue, cfg.sourceTimeZone);
        let destinationValue = sourceValue.isValid() ? sourceValue.clone().tz(cfg.destinationTimeZone).format(momentFormat) : null;

        if (!destinationValue) return null;

        return destinationValue;
    },

    currentTimeZones() {
        var currentDate = (new Date()).toString();
        var tz = currentDate.substring(currentDate.indexOf("(") + 1, 100);
        tz = tz.substring(0, tz.length - 1);
        return [tz, Intl.DateTimeFormat().resolvedOptions().timeZone].join(",");
    },

    nowClient () {
        // This value is "now" based on what it would look given what you're "viewing as" (which MAY NOT be the same as your desktop).
        let clientNow = DateTimeHelper.now();

        let converted = DateTimeHelper.timeZoneConvert({
            dateTime: clientNow,
            sourceTimeZone: moment.tz.guess(), //Intl.DateTimeFormat().resolvedOptions().timeZone,
            destinationTimeZone: Store.getters.clientTimeZone
        })

        return converted;
    },

    nowClientStartOfDay () {
        return moment(DateTimeHelper.nowClient()).startOf('day').format(momentFormat);
    },

    nowTenant () {
        // This value is "now" based on what it would look like in tenant-based time.
        let clientNow = DateTimeHelper.now();

        let converted = DateTimeHelper.timeZoneConvert({
            dateTime: clientNow,
            sourceTimeZone: moment.tz.guess(), //Intl.DateTimeFormat().resolvedOptions().timeZone,
            destinationTimeZone: Store.getters.tenantTimeZone
        })

        return converted;
    },

    nowTenantStartOfDay () {
        return moment(DateTimeHelper.nowTenant()).startOf('day').format(momentFormat);
    },

    now () {
        // This value is based on the value you see on your desktop (NOT necessarily what you're "viewing as" in the UI.)
        return moment().format(momentFormat);
    },

    nowStartOfDay () {
        return moment(DateTimeHelper.now()).startOf('day').format(momentFormat);
    },

    toLocalTimeString(dateTimeString, format){
        if(!format){
            format = DateTime.DATE_SHORT;
        }
        return DateTime.fromISO(dateTimeString, {zone: 'utc'}).toLocal().toLocaleString(format);
    },

    toFormat(dateTimeValue, format="MM/dd/yyyy") {
        let dtVal = null;
        if(_.isString(dateTimeValue)) {
            if(_.isEmpty(dateTimeValue)) return "";
            dtVal = DateTime.fromISO(dateTimeValue);
        }
        if(_.isDate(dateTimeValue)) {
            dtVal = DateTime.fromJSDate(dateTimeValue);
        }
        return _.isNil(dtVal) ? "" : dtVal.toFormat(format);
    },

    getDateParts(dtString, partFormat="yyyy-MM-dd'T'HH:mm:ss") {
        if(_.isEmpty(dtString)) return { date: null, time: null, timezone: null };
        let dt = DateTime.fromISO(dtString, { setZone: true });
        return {
            date: dt.startOf("day").toFormat(partFormat),
            time: dt.toFormat(partFormat),
            timezone: dt.zoneName
        };
    },

    joinDateParts({ date, time, timezone, format="yyyy-MM-dd'T'HH:mm:ss" }) {
        if(_.isNullOrEmpty(date)) return null;

        let dateDt = _.isDate(date)
            ? DateTime.fromJSDate(date)
            : DateTime.fromISO(date);

        let timeDt = _.isNullOrEmpty(time)
            ? null
            : _.isDate(time)
                ? DateTime.fromJSDate(time)
                : DateTime.fromISO(time);

        if (!dateDt.isValid) return null;

        let dtConfig = {
            year: dateDt.year,
            month: dateDt.month,
            day: dateDt.day
        };
        if (!_.isNil(timeDt) && timeDt.isValid) {
            dtConfig.hour = timeDt.hour;
            dtConfig.minute = timeDt.minute;
            dtConfig.second = timeDt.second;
        }

        let dtOpts = { zone: _.isEmpty(timezone) || timezone === "UTC" ? "utc" : timezone };

        let resultDt = DateTime.fromObject(dtConfig, dtOpts);

        return _.toLower(format) === "iso"
            ? resultDt.toISO()
            : resultDt.toFormat(format);
    },

    getOffset(timeZoneId=null) {
        return TimeZoneInfo.getOffset(timeZoneId);
    }
}

export default DateTimeHelper;