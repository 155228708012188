import authentication from './authentication';
import configuration from './configuration';
import client from './client';
import order from './order';
//import titleCompany from './titleCompany';
import landingPage from './landingPage';
import user from './user';
import permissions from './permissions';
import common from './common';
//import esign from './esign';

export default {
    configuration,
    landingPage,
    authentication,
    client,
    order,
    // titleCompany,
    user,
    permissions,
    common,
};