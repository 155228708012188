<template>
    <div :class="{ 'app': true, 'phone': isMobile }">  <!-- :class="isMobile ? 'phone' : ''" -->
        <router-view></router-view>
        <rq-dialog-manager />
        <rq-tooltip-manager />
        <rq-busy-manager />
    </div>
</template>
<script>
import "devextreme/integration/jquery";
import { mapGetters, mapState } from "vuex";
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { SET_ERROR } from '@/store/mutations';
import { GlobalEventManager, GLOBAL_EVENTS } from "./app.events";
import { RqTooltipManager, RqBusyManager } from "@/shared/components/rq/";
import { useGlobalDialogs, useIdleJs } from "@/shared/composables";
import { useRqToast } from "@/shared/plugins/composables";

const embedCss = css => {
    let styleElement = document.createElement("style")
    styleElement.setAttribute("type", "text/css");
    styleElement.innerHTML = css;
    document.head.appendChild(styleElement);
};

export default {
    name: 'app',
    components: {
        RqTooltipManager,
        RqBusyManager
    },
    data() {
        return {
        }
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const appError = computed(() => store.state.error);
        const isAuthenticated = computed(() => store.state.authentication.isAuthenticated);

        const { toastWarning } = useRqToast();

        const {
            registerDialogEvents,
            unregisterDialogEvents
        } = useGlobalDialogs();

        const { startIdleJs } = useIdleJs({ notifyMsRemaining: 60000 });

        function handleAction(e) {
            if(!_.startsWith(e.key, "app-")) return;
            // let currentRouteName = router.currentRoute.value.name;

        }

        return {
            isAuthenticated,
            appError,
            startIdleJs,
            handleAction,
            registerDialogEvents,
            unregisterDialogEvents,
            toastWarning
        };
    },
    computed: {
        ...mapGetters(["isMobile", "user", "site"]),
        ...mapState({
            userAgent: state => state.userAgent,
        }),
    },
    created() {
    },
    mounted() {
        this.registerDialogEvents();
    },

    beforeUnmount() {
        GlobalEventManager.unregister(this);
        this.unregisterDialogEvents();

        if(_.isNil(this.scrollStartEventName)) return;
        this.$events.off(this.scrollStartEventName, this.hideTopDxOverlay);
    },
    methods: {
        checkForErrorInfo() {
            const self = this;
            const errorIds = ["INACCESSIBLE_ROUTE", "INVALID_ORDER", "NO_LAST_ORDER"];
            let lastError = self.$store.getters.lastError;
            let displayError = "";

            if(_.isEmpty(lastError)) return;

            _.forEach(errorIds, errorId => {
                if(!_.startsWith(lastError, errorId)) return;
                displayError = _.replace(lastError, `${errorId}::`, "");
                return false;
            });

            if(_.isEmpty(displayError)) return;

            self.toastWarning(displayError);
            self.$store.commit(SET_ERROR, "");
        },

        handleMessage(message){
            const self = this;

        },
    }
}
</script>