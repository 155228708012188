import { MainLayout } from "@/layouts";
import { Error401, Error404 } from "@/pages";
import ConfigurationModule from '@/modules/configuration';
import FileMaintenanceModule from '@/modules/fileMaintenance';
import DashboardModule from '@/modules/dashboard';
import ConsumerModule from '@/modules/consumer/routes';
import RouteGuards from '../route-guards';

export const routes = [
    {
        path: '/',
        name: 'home',
        component: MainLayout,
        meta: {
            requiresAuth: true
        },
        // beforeEnter: RouteGuards().homeGuard//TODO
    },
    {
        path: "/:pathMatch(.*)*",
        component: MainLayout,
        meta: { label: "404", skipSaveOnLeave: true },
        children: [
            { name: "404", path: "", component: Error404, meta: { label: "Page Not Found", skipSaveOnLeave: true, requiresAuth: false } }
        ]
    },
    {
        path: "/NotAuthorized",
        component: MainLayout,
        name: '401',
        meta: { label: "401", skipSaveOnLeave: true },
        children: [
            { name: "NotAuthorized", path: "", component: Error401, meta: { label: "Not Authorized", skipSaveOnLeave: true, requiresAuth: false }, props: true }
        ]
    },
    // {
    //     path: '/AccessDenied',
    //     name: 'accessDenied',
    //     component: () => import(/* webpackChunkName: "accessDenied" */ '@/views/AccessDenied.vue'),
    //     meta: {
    //     }
    // },
    {
        path: '/MfaRegistration',
        name: 'mfaregistration',
        props: true,
        component: () => import(/* webpackChunkName: "mfaregistration" */ '@/modules/consumer/mfa/MfaRegistration.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/Consumer',
        name: 'consumer',
        redirect: { name: 'consumer:orders' },
        component: () => import(/* webpackChunkName: "consumerHome" */ '@/views/Consumer.vue'),
        meta: {
                requiresAuth: true,
                requireMfa: true,
                userAspect: "Consumer",
        },
        children: [
            ...ConsumerModule
        ]
    },
    {
        path: '/smktEsign',
        name: 'smktEsign',
        component: () => import(/* webpackChunkName: "smktEsign" */ '@/modules/consumer/esignmodules/SmktEsign.vue'),
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/titleCompany',
        component: MainLayout,
        name: "titlecompany",
        redirect: { name: 'titlecompany:fmfiles' },
        meta: {
            requiresAuth: true,
            label: "Title Company",
            include: false,
            userAspect: "Title",
        },
        children: [
            DashboardModule,
            FileMaintenanceModule,
            ConfigurationModule
        ]
    }
];