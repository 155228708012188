import { DateTime } from "luxon";

//update this if any changes are made to the log item object definition so it clears out older logs
const LOG_VERSION = "1.0.0";

const LOG_STORE_DEFAULTS = {
    consoleOutput: false,
    storageKey: `hzn_log_storage_${window.location.origin}`,
    retainValue: 3,
    retainUnit: "days"
};

class LogService {
    constructor({
        consoleOutput=LOG_STORE_DEFAULTS.consoleOutput,
        storageKey=LOG_STORE_DEFAULTS.storageKey,
        retainValue=LOG_STORE_DEFAULTS.retainValue,
        retainUnit=LOG_STORE_DEFAULTS.retainUnit
    }=LOG_STORE_DEFAULTS) {
        this.consoleOutput = consoleOutput;
        this.storageKey = storageKey;
        this.retainValue = retainValue;
        this.retainUnit = retainUnit;
    }

    getLogs() {
        let storageValue = localStorage.getItem(this.storageKey);
        return _.isEmpty(storageValue) ? [] : JSON.parse(storageValue);
    }

    setLogs(logs) {
        let trimmedLogs = _.filter(logs, l => l.ver === LOG_VERSION && Math.abs(DateTime.fromISO(l.timestamp).diffNow().as(this.retainUnit)) < this.retainValue);
        localStorage.setItem(this.storageKey, JSON.stringify(trimmedLogs));
    }

    logInfo(message, ...attachments) {
        this.log({
            level: "info",
            message,
            attachments
        });
    }

    logError(message, error, ...attachments) {
        this.log({
            level: "error",
            message,
            error,
            attachments
        });
    }

    log({ level="info", message="", attachments=[], error=null }) {
        let logItem = {
            ver: LOG_VERSION,
            level,
            message,
            attachments,
            error,
            pathname: window.location.pathname,
            timestamp: DateTime.now().toISO()
        };

        if(this.consoleOutput) {
            switch(level) {
                case "info":
                    console.info(message, ...attachments);
                    break;
                case "error":
                    console.error(message, error);
                    break;
                default:
                    console.log(message, ...attachments);
                    break;
            }
        }

        let logs = this.getLogs();
        logs.push(logItem);
        this.setLogs(logs);
    }

    report() {
        this.output("table");
    }

    dump() {
        this.output();
    }

    output(type="log") {
        let logs = this.getLogs();
        switch(type) {
            case "table":
                console.table(logs);
                break;
            default:
                console.log(logs);
                break;
        }
    }
}

const logService = new LogService();

window.__hzn_log_storage = {
    report: () => logService.report(),
    dump: () => logService.dump()
};

export default logService;