<template>
    <div class="app rq-app-restricted">
        <IssueDisplay
            v-if="isCustom"
            :icon="icon"
            :title="title"
            :message="message"
        />
        <component v-else
            :is="issueComponent"
        />
    </div>
</template>

<script setup>
    import { computed } from "vue";
    import { APP_ISSUE_CODE } from "./AppConfig";
    import {
        IssueDisplay,
        ApplicationUnavailable,
        InvalidUser,
        MissingUser,
        UnsupportedDevice
    } from "./pages/issues";

    const props = defineProps({
        issueCode: { type: String, default: "custom" },
        icon: { type: String, default: "" },
        title: { type: String, default: "" },
        message: { type: String, default: "" }
    });

    const {
        UNHANDLED,
        UNSUPPORTED_DEVICE,
        MISSING_USER,
        INVALID_USER
    } = APP_ISSUE_CODE;

    const isCustom = computed(() => props.issueCode === "custom");
    const issueComponent = computed(() => {
        switch(props.issueCode) {
            case UNHANDLED: return ApplicationUnavailable;
            case UNSUPPORTED_DEVICE: return UnsupportedDevice;
            case MISSING_USER: return MissingUser;
            case INVALID_USER: return InvalidUser;
        }
        return null;
    });

    if(props.issueCode !== "custom" && (!_.isEmpty(props.icon) || !_.isEmpty(props.title) || !_.isEmpty(props.message))) {
        console.warn(`ready2close - AppRestricted :: The value "custom" must be passed for the prop "issueCode" to use the custom "icon", "title", and "message" props.`);
    }
</script>