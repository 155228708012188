//shared
export const START_WAIT = "rqBusy_StartWait";
export const END_WAIT = "rqBusy_EndWait";
export const END_ALL_WAITS = "rqBusy_EndAll";
export const SET_WAIT_MESSAGE = "rqBusy_SetMessage";

// authorization
// export const GET_LANDING_PAGE_FOR_ORDER = "getLandingPageForOrder";
export const SET_VIEW_AS = "setViewAs";
export const CLEAR_VIEW_AS = "clearViewAs";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const SET_TOKEN = "setToken";
export const UPDATE_SESSION_USER = "updateSessionUser";
export const AUTH_ACTIONS = {
    // GET_LANDING_PAGE_FOR_ORDER,
    SET_VIEW_AS,
    CLEAR_VIEW_AS,
    LOGIN,
    LOGOUT,
    SET_TOKEN,
    UPDATE_SESSION_USER
};

export const LOGIN_UI_USER = "loginUIUser";
export const USER_PAGE_ACTION = {
    LOGIN_UI_USER
}
