import { SET_REALM } from '../mutations';
const state = {
    realm: null
};

const getters = {
    realm: state => state.realm
}

const mutations = {
    [SET_REALM] (state, value) {
        state.realm = value;
    }
}

const actions = {
}

export default { state, getters, actions, mutations };