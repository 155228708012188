import { GlobalEventManager, GLOBAL_EVENTS } from "../../app.events";

const getTooltipKey = el => _.get(el, "dataset.rqTooltipKey", null);

const createTooltipOptions = (el, { value, modifiers }) => {
    let titleAttr = el.getAttribute("title");
    let opts = (_.isNil(value) && !_.isEmpty(titleAttr))
        ? { title: titleAttr }
        : _.isString(value)
            ? { title: value }
            : { ...value };

    opts.key = getTooltipKey(el);

    let triggersList = opts.trigger || [];
    _.each(modifiers, (v,k) => {
        if(!v) return;
        if(_.includes(["hover","click","focus"], k)) triggersList.push(k);
        if(_.includes(["top","left","bottom","right"], k)) opts.placement = k;
    });

    if(!_.isEmpty(triggersList)) {
        let optsTriggers = opts.trigger || "";
        let finalTriggersList = _.isEmpty(optsTriggers)
            ? triggersList
            : _.concat(_.split(optsTriggers, " "), triggersList);

        opts.trigger = _.join(_.uniq(finalTriggersList), " ");
    }

    if(!opts.target) opts.target = el;

    return opts;
};

const handleTooltipEvent = (event, el, binding) => {
    let tooltipOptions = createTooltipOptions(el, binding);
    if(_.isEmpty(tooltipOptions)) return;
    GlobalEventManager.emit(event, tooltipOptions);
};

export default {
    mounted(el, binding) {
        handleTooltipEvent(GLOBAL_EVENTS.REGISTER_TOOLTIP, el, binding);
    },
    updated(el, binding) {
        handleTooltipEvent(GLOBAL_EVENTS.UPDATE_TOOLTIP, el, binding);
    },
    beforeUnmount(el, binding) {
        GlobalEventManager.emit(GLOBAL_EVENTS.REMOVE_TOOLTIP, getTooltipKey(el));
    }
};
