import accounting from "accounting";
import { createApp } from 'vue';
import { rqdxActionDataGrid } from "vendor/rq-devextreme-vue.esm";
import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

const getIconSvg = (symbolId, classAttr) => `<svg class="${classAttr}"><use href="#${symbolId}"></use></svg>`;

const sortingMethod = (value1, value2) => {
    // Handling null values
    if(!value1 && value2) return -1;
    if(!value1 && !value2) return 0;
    if(value1 && !value2) return 1;
    // Case-insensitive
    return value1.localeCompare(value2, undefined, { sensitivity: 'accent' });
};
const formatPhone = (phone) => {
    phone = (phone + '').trim();
    if (!phone || phone.length != 10) {
        return phone;
    }
    return "(" + phone.substr(0,3) + ") " + phone.substr(3,3) + "-" + phone.substr(6,4);
};

function getInfoIcon({ cellElement, cellInfo, idAppend="info-icon-", activeIconSymbol, inactiveIconSymbol }, handlers) {
    let iconSvg = getIconSvg(inactiveIconSymbol, "rq-cell-info-icon");

    function onMouseEvent(e, eventName, symbolId) {
        $(e.currentTarget).find("use").attr("href", `#${symbolId}`);
        if(!_.has(handlers, eventName)) return;
        _.invoke(handlers, eventName, cellElement, cellInfo, e);
    }
    let $infoIcon = $(iconSvg)
        .attr("id", _.uniqueId(idAppend))
        .on("mouseenter", function(e) { onMouseEvent(e, "mouseenter", activeIconSymbol); })
        .on("mouseleave", function(e) { onMouseEvent(e, "mouseleave", inactiveIconSymbol); });

    _.forEach(handlers, (eventFunction, eventName) => {
        if(_.includes(["mouseenter", "mouseleave"], eventName) || !_.isFunction(eventFunction)) return;
        // console.log("registering event - %s", eventName);
        $infoIcon.on(eventName, function(e) { eventFunction(cellElement, cellInfo, e); });
    });

    return $infoIcon;
}

export function dateTimeWithZoneCellTemplate(config) {
    let cfg = _.defaultsDeep({}, config, { format: 'MM/DD/YYYY hh:mm a', fallback: '' });

    return (cellElement, cellInfo) => {
        if (!cellInfo.value) return;
        // Make the conversion and display accordingly.
        let displayValue = DateTimeHelper.timeZoneConvert({
            dateTime: cellInfo.value,
            // sourceTimeZone: cfg.sourceTimeZone,
            // destinationTimeZone: cfg.destinationTimeZone,
        });
        let momentDate = moment(displayValue);
        cellElement.text(momentDate.isValid() ? momentDate.format(cfg.format) : (cfg.fallbackText || ''));
    };
}

export function lookupDataSource ({ filter, ...rest }) {
    return (e) => {
        return {
            ...rest,
            filter: (lookupItemData) => {
                return _.isEmpty(e)
                    ? true
                    : _.isFunction(filter)
                        ? filter(e, e.data, lookupItemData)
                        : _.parseBool(filter, true)
            }
        }
    }
}

export function lookupSort (displayExpr) {
    return {
        calculateSortValue: function(rowData) {
            let value = this.calculateCellValue(rowData);

            let items = _.get(this.lookup, 'items', []);
            let item = _.find(items, { [this.lookup.valueExpr]: value });

            return _.get(item, displayExpr);
        },
        sortingMethod
    }
}

export const lookupSortDisplayExpr = {
    calculateSortValue: function(rowData) {
        let value = this.calculateCellValue(rowData);
        return this.lookup.calculateCellValue(value);
    },
    sortingMethod
};

export function lookupSortCustomLookup ({dataField, lookups, valueExpr, displayExpr}={}) {
    return {
        calculateSortValue: function(rowData) {
            let id = _.get(rowData, dataField);
            let lookup = _.find(lookups, { [valueExpr]: id });

            let sortValue = _.get(lookup, displayExpr, '');

            return sortValue;
        },
        sortingMethod
    }
}

export function dateCellTemplateSortValue(dataField, format) {
    return {
        calculateSortValue: function(rowData) {
            let sortField = _.get(rowData, dataField, "") || "";
            var sortValue = DateTimeHelper.toFormat(sortField, format)
            return sortValue;
        },
        sortingMethod
    }
}

export function regionDisplaySortValue (rowData) {
    let regionDisplay = _.get(rowData, "regionDisplay", "") || "";
    if (!_.isEmpty(regionDisplay)) {
        let regID = _.split(regionDisplay, " - ");
        return _.parseNumber(regID[0]);
    }
    return rowData.Position;
}

export function boolCellTemplate (cellElement, cellInfo) {
    if (!_.parseBool(cellInfo.value)) return;
    cellElement
        .addClass("p-0")
        .append(getIconSvg("rq-fas-check", "bool-cell-icon"));
}

export function boolPopoverCellTemplate({ idAppend="popup-info-", handlers={} }) {
    return function(cellElement, cellInfo) {
        if (!_.parseBool(cellInfo.value)) return;
        let iconSvg = getIconSvg("rq-fas-check", "bool-cell-icon-popover");

        function onMouseEvent(e, eventName, symbolId) {
            $(e.currentTarget).find("use").attr("href", `#${symbolId}`);
            if(!_.has(handlers, eventName)) return;
            _.invoke(handlers, eventName, cellElement, cellInfo, e);
        }
        let $boolIcon = $(iconSvg)
            .attr("id", _.uniqueId(idAppend))
            .on("mouseenter", function(e) { onMouseEvent(e, "mouseenter", "rq-fas-check"); })
            .on("mouseleave", function(e) { onMouseEvent(e, "mouseleave", "rq-fas-check"); });

        _.forEach(handlers, (eventFunction, eventName) => {
            if(_.includes(["mouseenter", "mouseleave"], eventName) || !_.isFunction(eventFunction)) return;
            $boolIcon.on(eventName, function(e) { eventFunction(cellElement, cellInfo, e); });
        });

        cellElement
        .addClass("text-center p-0")
        .append($boolIcon);
    };
}

export function pillCellTemplate ({lookups=[]}) {
    return function(cellElement, cellInfo) {
        if (_.size(cellInfo.text) == 0) return;
        let text = cellInfo.text || "";

        var status = _.find(lookups, ["status", text]) || {status: "Unknown", variant: "secondary"};
        $("<span/>")
        .addClass(`badge badge-pill bg-${status.variant}`)
        .attr("title", text)
        .append(text)
        .appendTo(cellElement);
    };
}

export function reconDateCellTemplate(cellElement, cellInfo) {
    let $displayContainer = $("<div/>").addClass("d-flex justify-content-between");
    let $reconDate = $("<span />");
    let $tooltip = $("<span/>");
    if (!_.isNil(cellInfo.data.reconciliationDate)) $reconDate.append(moment(cellInfo.data.reconciliationDate).format("MM/DD/YYYY"));

    let $infoIcon = null;
    if (_.parseBool(cellInfo.data.isReconFinalized, false) && !_.isNil(cellInfo.data.reconciliationDate)) {
        $infoIcon = $(getIconSvg("rq-fas-check-circle", "rq-cell-check-circle active"));
        $tooltip
            .append('Finalized')
            .dxTooltip({target: $infoIcon, wrapperAttr: { class: "rq-dx-tooltip" }, position: "top", showEvent: "mouseenter", hideEvent: "mouseleave"});
    } else if (!_.parseBool(cellInfo.data.isReconFinalized, false) && !_.isNil(cellInfo.data.reconciliationDate)) {
        $infoIcon = $(getIconSvg("rq-fas-clock", "rq-cell-clock inactive"));
        $tooltip
            .append('Unfinalized')
            .dxTooltip({target: $infoIcon, wrapperAttr: { class: "rq-dx-tooltip" }, position: "top", showEvent: "mouseenter", hideEvent: "mouseleave"});
    }

    $displayContainer
        .append($reconDate)
        .append($tooltip)
        .append($infoIcon || "")
        .appendTo(cellElement);
}

export function ledgerAmountCellTemplate(cellElement, cellInfo) {
    let leftArrow = getIconSvg("rq-fas-arrow-alt-square-left", `rq-cell-arrow-icon rq-cell-arrow-left me-auto ${_.parseBool(cellInfo.data.isCheck) ? "inactive" : "active"}`);
    let rightArrow = getIconSvg("rq-fas-arrow-alt-square-right", `rq-cell-arrow-icon rq-cell-arrow-right ms-2 ${_.parseBool(cellInfo.data.isCheck) ? "active" : "inactive"}`);
    $("<div />").addClass("d-flex justify-content-between")
        .append(leftArrow)
        .append(cellInfo.text)
        .append(rightArrow)
        .appendTo(cellElement);
}

export function autoReconIssueCellTemplate({ idAppend="popup-info-", activeIconSymbol="rq-far-info-circle", inactiveIconSymbol="rq-fas-info-circle", handlers={} }) {
    return function(cellElement, cellInfo) {
        let $text = $("<span/>").append(_.size(cellInfo.text) > 1 ? cellInfo.text : '(Blank)');
        let hasPartialMatch = _.getNumber(cellInfo.data, "depositID", 0) + _.getNumber(cellInfo.data, "checksID", 0) > 0;
        let $infoIcon = getInfoIcon({ cellElement, cellInfo, idAppend, activeIconSymbol, inactiveIconSymbol }, handlers);

        $("<div/>")
            .addClass("d-flex justify-content-between")
            .append($text)
            .append(hasPartialMatch ? $infoIcon : "")
            .appendTo(cellElement);
    };

}

export function checkStatusCellTemplate({ idLookup="", statuses=[], idAppend="popup-info-", activeIconSymbol="rq-far-info-circle", inactiveIconSymbol="rq-fas-info-circle", handlers={} }) {
    return function(cellElement, cellInfo) {
        var status = _.find(statuses, ["id", _.get(cellInfo.data, idLookup)]);
        var showOverride = cellInfo.data.isRecoverable && !cellInfo.data.isRecovered;
        let $text = $("<span/>").append(status.name);
        let $infoIcon = cellInfo.data.hasApprovalInfo
            ? getInfoIcon({ cellElement, cellInfo, idAppend, activeIconSymbol, inactiveIconSymbol }, handlers)
            : null;

        if (showOverride) cellElement.addClass("rq-overridden");
        $("<div/>")
            .addClass("d-flex justify-content-between")
            .append($text)
            .append($infoIcon)
            .appendTo(cellElement);
    };
}

export function standardLanguageDescriptionCellTemplate({ idAppend="popup-info-", activeIconSymbol="rq-far-info-circle", inactiveIconSymbol="rq-fas-info-circle", handlers={} }) {
    return function(cellElement, cellInfo) {
        let rowData = cellInfo.data;
        let classNames = ["clause-description"];
        let iconClass = "rq-fal-file-invoice";

        if(rowData.isPackage) {
            classNames.push("pkg-description");
            iconClass = "rq-fas-folder";
        }

        let $clauseText = $("<div>").addClass("clause-text").text(cellInfo.text);
        let $clauseIcon = $("<div>").addClass("clause-icon").append(getIconSvg(iconClass, "rq-icon-symbol"));
        let $infoIcon = !_.isNullOrEmpty(rowData.notes) ?
                        getInfoIcon({ cellElement, cellInfo, idAppend, activeIconSymbol, inactiveIconSymbol }, handlers)
                        : "";

        $("<div>")
            .addClass(classNames)
            .append($clauseIcon)
            .append($clauseText)
            .append($infoIcon)
            .appendTo(cellElement);
    };
}

export function orderTasksNotesCellTemplate({ idAppend="popup-info-", activeIconSymbol="rq-far-info-circle", inactiveIconSymbol="rq-fas-info-circle", handlers={} }) {
    return function(cellElement, cellInfo) {
        let $text = $("<span/>").append(cellInfo.text);
        let $infoIcon = !_.isNullOrEmpty(cellInfo.data.taskNote) ?
                        getInfoIcon({ cellElement, cellInfo, idAppend, activeIconSymbol, inactiveIconSymbol }, handlers)
                        : "";
        $("<div>")
            .addClass("d-flex justify-content-between")
            .append($text)
            .append($infoIcon)
            .appendTo(cellElement);
    };

}

export function textPlusCountCellTemplate({ idAppend="popup-info-", activeIconSymbol="rq-far-info-circle", inactiveIconSymbol="rq-fas-info-circle", handlers={} }) {
    return function(cellElement, cellInfo) {
        let $text = $("<span/>").append(_.size(cellInfo.text) > 1 ? cellInfo.text : '(Blank)');
        let $infoIcon = getInfoIcon({ cellElement, cellInfo, idAppend, activeIconSymbol, inactiveIconSymbol }, handlers);

        let $lineCount = $("<span/>");
        if (_.parseNumber(cellInfo.data.lineCount, 0) > 1) {
            $lineCount
                .addClass("rq-cell-count-info")
                .append(`(${cellInfo.data.lineCount})`)
                .appendTo($text);
        }
        $("<div/>")
            .addClass("d-flex justify-content-between")
            .append($text)
            .append($infoIcon)
            .appendTo(cellElement);
    };
}

export function dateCellTemplate(cellElement, cellInfo) {
    if(!cellInfo.value) return;
    cellElement.text(DateTimeHelper.toFormat(cellInfo.value));
}

export function dateCellTemplateMMDD(cellElement, cellInfo) {
    if(!cellInfo.value) return;
    cellElement.text(DateTimeHelper.toFormat(cellInfo.value, "MM/dd"));
}

export function dateColumn(options) {
    options = options || {};

    let required = _.getBool(options, 'required', false);

    options = _.defaultsDeep(options, {
        dataType: 'date',
        cellTemplate: DxGridUtils.dateCellTemplate,
        editorOptions: {
            type: "date",
            displayFormat: "MM/dd/yyyy",
            useMaskBehavior: true,
            dateSerializationFormat: 'yyyy-MM-dd',
            customItemCreateEvent: "change"
        },
    });

    if (required) {
        if (!_.isArray(options.validationRules) || _.isEmpty(options.validationRules)) {
            options.validationRules = [];
        }
        options.validationRules.push({ type: 'required' });
    }

    return options;
}

export function dateTimeColumn(options) {
    options = options || {};

    let required = _.getBool(options, 'required', false);

    options = _.defaultsDeep(options, {
        dataType: 'datetime',
        cellTemplate: DxGridUtils.dateTimeCellTemplate,
    });

    if (required) {
        if (!_.isArray(options.validationRules) || _.isEmpty(options.validationRules)) {
            options.validationRules = [];
        }
        options.validationRules.push({ type: 'required' });
    }

    return options;
}

export function dateTimeCellTemplate(cellElement, cellInfo) {
    let func = DxGridUtils.dateTimeWithZoneCellTemplate({ format: 'MM/DD/YYYY hh:mm a' });
    func(cellElement, cellInfo);
}

export function dateOnlyTimeZoneDependentCellTemplate(cellElement, cellInfo) {
    DxGridUtils.dateTimeWithZoneCellTemplate({ format: 'MM/DD/YYYY' })(cellElement, cellInfo);
}

export function timeCellTemplate(cellElement, cellInfo) {
    if(!cellInfo.value) return;
    cellElement.text(moment(cellInfo.value).format('LT'));
}

export function moneyCellTemplate(cellElement, cellInfo) {
    cellElement.text(accounting.formatMoney(cellInfo.displayValue));
}

export function phoneCellTemplate(cellElement, cellInfo) {
    if(!cellInfo.value) return;
    cellElement.text(formatPhone(cellInfo.value));
}

export function priorityCellTemplate(cellElement, cellInfo) {
    let iconSvg = getIconSvg("rq-fas-circle", `note-priority-icon note-priority-${ cellInfo.value || "0" }`);
    $("<div />")
        .addClass("text-center")
        .append(iconSvg)
        .appendTo(cellElement);
}

export function truncateCellTemplate(cellElement, cellInfo) {
    let text = cellInfo.text || "";
    $("<span/>")
    .addClass("text-truncate")
    .attr("title", text)
    .append(text)
    .appendTo(cellElement);
}

export function truncatedCellTemplate(cellElement, cellInfo, length, encodeHtml=false) {
    let note = (encodeHtml ? _.escape(cellInfo.text) : cellInfo.text) || "";
    if (note.length > length) {
        let noteShort = _.truncate(note, {'length': length, 'separator': ' '});
        $("<span/>")
            .attr("title", note)
            .append(noteShort)
            .appendTo(cellElement);
    } else {
        $("<span/>")
            .append(note)
            .appendTo(cellElement);
    }
}

export function rateCellTemplate(cellElement, cellInfo) {
    cellElement.text(accounting.formatNumber(cellInfo.displayValue, 3) + ' %');
    accounting.toFixed();
}

export function statusCellTemplate(cellElement, cellInfo){

    let isComplete = _.parseBool(cellInfo.data.isComplete);
    let isOverDue = _.parseBool(cellInfo.data.isOverdue) || _.parseBool(cellInfo.data.isOverDue);
    let isNotApplicable = _.parseBool(cellInfo.data.notApplicableYN) || _.parseBool(cellInfo.data.notApplicable);

    let statusClass = "task-open";
    let toolTip = "Open";

    if(isNotApplicable) {
        statusClass = "task-not-applicable-status";
        toolTip = "Not Applicable";
    }
    else if(isComplete) {
        statusClass = "task-complete";
        toolTip = "Complete";
    }
    else if(isOverDue) {
        statusClass = "task-overdue";
        toolTip = "Overdue";
    }

    let iconSvg = getIconSvg("rq-fas-circle", `status-cell-icon ${statusClass}`);
    $("<div />")
        .attr("title", toolTip)
        .addClass("text-center")
        .append(iconSvg)
        .appendTo(cellElement);
}

export function tooltipCellTemplate({
    cellTemplate=null,
    content=null,
    position="top",
    showEvent="mouseenter",
    hideEvent="mouseleave",
    targetCell=false
}={
    cellTemplate:null,
    content:null,
    position:"top",
    showEvent:"mouseenter",
    hideEvent:"mouseleave",
    targetCell:false }) {
    return (cellElement, cellInfo) => {
        let $cellContent = null;
        if(_.isFunction(cellTemplate)) {
            $cellContent = $("<div/>");
            let $templateResult = cellTemplate(cellInfo, $cellContent);
            if(!_.isNil($templateResult)) $cellContent.append($templateResult);
        }
        else {
            $cellContent = $("<span/>").text(cellInfo.text);
        }
        let tooltipText = _.isFunction(content) ? content(cellInfo) : _.isString(content) ? content : cellInfo.text;
        if(!_.isEmpty(tooltipText)) {
            let $tooltip = $("<span/>")
                .append(tooltipText)
                .dxTooltip({
                    target: targetCell ? cellElement : $cellContent,
                    wrapperAttr: { class: "rq-dx-tooltip" },
                    position,
                    showEvent,
                    hideEvent
                });
            cellElement.append($tooltip)
        }
        cellElement.append($cellContent);
    };
}

export function infoIconCellTemplate({ displayValueTemplate=null, activeIconSymbol="rq-far-info-circle", inactiveIconSymbol="rq-fas-info-circle", handlers={} }){
    return function(cellElement, cellInfo) {
        let $infoIcon = getInfoIcon({ cellElement, cellInfo, activeIconSymbol, inactiveIconSymbol }, handlers);
        let infoClass = "d-flex justify-content-between";
        if (_.isEqual(_.get(cellInfo, "column.alignment"), "right")) {
            infoClass = "d-flex flex-row-reverse justify-content-between";
        }
        let $displayContainer = $("<div/>").addClass(infoClass);
        let $displayValue = _.isFunction(displayValueTemplate)
            ? displayValueTemplate($displayContainer, cellInfo)
            : $("<span />").append(cellInfo.text);

        if(!_.isNil($displayValue) && $displayValue !== $displayContainer)
            $displayContainer.append($displayValue);

        $displayContainer
            .append($infoIcon)
            .appendTo(cellElement);
    };
}

export function getTagFilterExpression(column, values) {
    if(_.isEmpty(values)) return null;
    let filters = [];
    if(values.length > 1) {
        _.forEach(values, (v,i) => {
            if(i > 0) filters.push("or");
            filters.push(column.calculateFilterExpression(v, "="));
        });
    }
    else
        filters = column.calculateFilterExpression(values[0], "=");
    filters.columnIndex = column.index;
    filters.filterValues = values.slice();
    return filters;
}

export function getColumnValues(grid, displayField, valueField=null) {
    let ds = grid.getDataSource();
    if(_.isNil(valueField) || displayField === valueField) {
        return _.uniq(_.map(ds.items(), item => item[displayField]));
    }
    else {
        let items = _.map(ds.items(), item => {
            return { key:item[valueField], value:item[displayField]  || "(No value)" };
        });
        return _.uniqBy(items, "key");
    }
}

export function createChildGrid(detailElement, cfg) {

    let config = cfg || {};

    if (_.isEmpty(_.get(config, 'config.columns'))) return;

    _.defaultsDeep(config, {
        config: {
            selection: {
                mode: "none",
                showCheckBoxesMode: "none",
            },
            hoverStateEnabled: true,
        }
    });

    detailElement.addClass("internal-grid-container");

    var mountPoint = $("<div>")
        .addClass("internal-grid")
        .appendTo(detailElement);

    const componentInstance = new createApp({
        ...rqdxActionDataGrid,
        propsData: {
            ...config
        }
    });

    componentInstance.$mount(mountPoint.get()[0]);

    return componentInstance.getGridInstance();
}

export function hasFieldFilter(opts, field) {
    let filters = _.get(opts, "filter", []);
    const parseFilter = f => {
        let result = false;
        if(!_.isArray(f) || _.isEmpty(f)) return result;
        _.each(f, (item, index) => {
            if(_.isArray(item))
                result = parseFilter(item);
            else if(_.isString(item) && index === 0)
                result = (item === field);
            return !result;
        });
        return result;
    }
    return parseFilter(filters);
}

export const actionLinkCellTemplate = ({ linkText="View Content", onClick=()=>{} }) =>
    (cellElement, cellInfo) => {
        $("<a/>")
            .addClass("rq-grid-action-link")
            .text(linkText)
            .on("click", () => onClick(cellElement, cellInfo))
            .appendTo(cellElement);
    };

const DxGridUtils = {
    lookupDataSource,
    lookupSort,
    lookupSortDisplayExpr,
    lookupSortCustomLookup,
    regionDisplaySortValue,
    boolCellTemplate,
    boolPopoverCellTemplate,
    pillCellTemplate,
    reconDateCellTemplate,
    ledgerAmountCellTemplate,
    autoReconIssueCellTemplate,
    checkStatusCellTemplate,
    standardLanguageDescriptionCellTemplate,
    textPlusCountCellTemplate,
    dateCellTemplate,
    dateCellTemplateMMDD,
    dateColumn,
    dateTimeColumn,
    dateTimeWithZoneCellTemplate,
    dateTimeCellTemplate,
    dateOnlyTimeZoneDependentCellTemplate,
    dateCellTemplateSortValue,
    timeCellTemplate,
    moneyCellTemplate,
    phoneCellTemplate,
    priorityCellTemplate,
    truncateCellTemplate,
    truncatedCellTemplate,
    rateCellTemplate,
    statusCellTemplate,
    tooltipCellTemplate,
    infoIconCellTemplate,
    actionLinkCellTemplate,
    getTagFilterExpression,
    getColumnValues,
    createChildGrid,
    hasFieldFilter,
    orderTasksNotesCellTemplate
};

export default DxGridUtils;
