import {SET_ERROR, SET_ERROR_MESSAGE, SET_IS_SAVING, SET_SAVED, SET_ACTION} from '../mutations';

const state = {
   isSaving: false,
   saved: false,
   action: '',
   error: false,
   erroMessage: '',
};

const getters = {
    isSaving: state => state.isSaving,
    saved: state=> state.saved,
    action: state=> state.action,
    error: state=> state.error,
    errorMessage: state => state.errorMessage,
}

const mutations = {
    [SET_IS_SAVING] (state, value) {
        state.isSaving = value;
    },
    [SET_SAVED] (state,value) {
        state.saved = value
    },
    [SET_ACTION] (state,value) {
        state.action = value
    },
    [SET_ERROR] (state,value) {
        state.error = value
    },
    [SET_ERROR_MESSAGE] (state,value) {
        state.errorMessage = value
    },
}
const actions = {
}

export default { state, getters, actions, mutations };