import {SET_LOGOUT_USER} from '../mutations';
import { CONSUMER_ORDER_MUTATIONS } from "../mutations"

const state = {
    logutUser:false,
    consumerOrderUniqueId: null,
    setLastLogin: true,
};

const getters = {
    logoutUser: state => state.logutUser,
    setLastLogin: state => state.setLastLogin,
    consumerOrderUniqueId: state => state.consumerOrderUniqueId
}

const mutations = {
    [SET_LOGOUT_USER] (state,value) {
        state.logutUser = value;
    },
    [CONSUMER_ORDER_MUTATIONS.CONSUMER_SET_ORDER_UNIQUE_ID] (state, value) {
        state.consumerOrderUniqueId = value;
    }
}

const actions = {
    [CONSUMER_ORDER_MUTATIONS.CONSUMER_SET_ORDER_UNIQUE_ID] ({ commit, dispatch }, data) {
        return new Promise((resolve) => {
            commit(CONSUMER_ORDER_MUTATIONS.CONSUMER_SET_ORDER_UNIQUE_ID, data);
            resolve();
        });
    }
}

export default { state, getters, actions, mutations };