import { AlertSeverity } from "./enums";
import { DateTime } from "luxon";

export class NewFileDialogModel {
    constructor (options) {
        options = options || {};
        this.gfNo = options.gfNo || "";
        this.workflowProcessTemplateID = options.workflowProcessTemplateID || "";
        this.regionID = _.parseNumber(options.regionID, null);
        this.branchID = _.parseNumber(options.branchID, null);
        this.titleUnitID = _.parseNumber(options.titleUnitID, null);
        this.escrowUnitID = _.parseNumber(options.escrowUnitID, null);
        this.kindOfOrder = options.kindOfOrder || 3;
    }
}

export class DuplicateOrderSearchModel {
    constructor (options) {
        options = options || {};
        this.buyerFirstName = options.buyerFirstName || "";
        this.buyerLastName = options.buyerLastName || "";
        this.buyerState = options.buyerState || "";
        this.sellerFirstName = options.sellerFirstName || "";
        this.sellerLastName = options.sellerLastName || "";
        this.sellerState = options.sellerState || "";
        this.propertyAddress = options.propertyAddress || "";
        this.propertySubdivision = options.propertySubdivision || "";
        this.propertyTaxId = options.propertyTaxId || null;
        this.allBranches = options.allBranches || false;
    }
}

export class SearchRequest {
    constructor (options) {
        options = options || {};
        this.parameters = options.parameters || {};
        this.parameterObjects = options.parameterObjects || {};
        this.sortBy = options.sortBy || [];
        this.skip = options.skip || 0;
        this.take = options.take || 50;
        this.pageNumber = options.pageNumber || (options.skip + 1);
        this.pageSize = options.pageSize || options.take;
        this.pagingEnabled = options.pagingEnabled || false;
    }
}

export class SortOption {
    constructor (options) {
        options = options || {};
        this.field = options.field || "";
        this.direction = options.direction || "";
    }

    static get SortDirection () {
        return {
            Ascending: "ASC",
            Descending: "DESC"
        };
    }
}

export class SaleModel {
    constructor (options) {
        options = options || {};
        this.salesPrice = options.salesPrice || 0;
        this.ownerPolicyLiability = options.ownerPolicyLiability || 0;
        this.earnestMoney = options.earnestMoney || 0;
        this.downPayment = options.downPayment || 0;
        this.earnestMoneyHeldBy = (options.earnestMoneyHeldBy === 0) ? 0 : (options.earnestMoneyHeldBy || null);
        this.typeFundID = options.typeFundID || null;
    }
}

export class SelectListItem {
    constructor (options) {
        options = options || {};
        this.id = options.id || 0;
        this.index = options.index || 0;
        this.text = options.text || "";
    }
}

export class BranchModel {
    constructor (options) {
        options = options || {};
        this.id = _.parseNumber(options.id, null);
        this.name = options.name || "";
        this.regionID = _.parseNumber(options.regionID, null);
        this.defaultState = options.defaultState || "";
        this.defaultCountyID = _.parseNumber(options.defaultCountyID, null);
        this.defaultCountyName = options.defaultCountyName || "";
    }
}
export class SystemLookupItem {
    constructor (options) {
        options = options || {};
        this.id = _.parseNumber(options.id, null);
        this.name = options.name || "";
        this.data = options.data || "";
        this.inactive = _.parseBool(options.inactive, false);
    }
}
export class SystemLookupItemDto {
    constructor (options) {
        options = options || {};
        this.id = _.parseNumber(options.id, null);
        this.name = options.name || "";
        this.regionID = _.parseNumber(options.regionID, 0);
        this.data = options.data || "";
        this.inactive = _.parseBool(options.inactive, false);
    }
}
export class LookupItem {
    constructor (options) {
        options = options || {};
        this.id = _.parseNumber(options.id, null);
        this.name = options.name || "";
        this.enabled = _.parseBool(options.enabled);
        this.data = options.data || null;
    }
}

export class ImageDataModel {
    constructor (options) {
        options = options || {};
        this.dataUrl = options.dataUrl || null;
        this.height = options.height || null;
        this.width = options.width || null;
        this.imageFormat = options.imageFormat || null;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class RoleModel {
    constructor (options) {
        options = options || {};
        this.rolesID = options.rolesID || 0;
        this.roleTypeID = options.roleTypeID || 0;
        this.ordersID = options.ordersID || 0;
        this.companyID = options.companyID || 0;
        this.companyName = options.companyName || null;
        this.contactID = options.contactID || 0;
        this.contactName = options.contactName || null;
        this.buyerSellerID = options.buyerSellerID || null;
        this.tableID = options.tableID || null;
        this.pcEnableAccess = options.pcEnableAccess || 0;
        this.pcDisplayInContacts = options.pcDisplayInContacts || 0;
        this.pcEmailEvents = options.pcEmailEvents || 0;
        this.pcEmailDocumentNotifications = options.pcEmailEvents || 0;
        this.pcEnableOrderNotes = options.pcEnableOrderNotes || 0;
        this.pcEnableWorkflow = options.pcEnableWorkflow || 1;
        this.pcEnableReceiptsDispursments = options.pcEnableReceiptsDispursments || 0;
        this.pcEnablePropertyInformation = options.pcEnablePropertyInformation || 1;
        this.pcEnableDocuments = options.pcEnableDocuments || 0;
        this.pcOverrideContact = options.pcOverrideContact || "";
        this.pcOverrideEmail = options.pcOverrideEmail || "";
        this.isBuyer = options.isBuyer || 0;
    }
}

export class ProgressItem {
    constructor (options) {
        options = options || {};
        this.key = options.key || _.uniqueId();
        this.label = options.label || options.description || `(NO LABEL) - ${this.key}`;
        this.description = options.description || options.label || `(No Description) - ${this.key}`;
        this.status = options.status || ProgressItem.status.loading;
        this.loadResult = options.loadResult || "";
    }

    static get status () {
        return {
            loading: 0,
            success: 1,
            failure: 2
        };
    }
}

export class TabItem extends ProgressItem {
    constructor (options) {
        super(options);
        options = options || {};
        this.key = options.key || _.uniqueId();
        this.value = options.value || this.label;
        this.disabled = _.parseBool(options.disabled, false);
        this.closeable = _.parseBool(options.disabled, true);
    }

    get progressItem () {
        return new ProgressItem(this);
    }
}

export class ModelState {
    constructor (options) {
        options = options || {};
        _.mergeWith(this, options, (thisVal, optVal) => new FieldState(optVal));
    }

    hasError (field = null) {
        if (!field) return !this.isValid;
        let fieldName = field.charAt(0).toUpperCase() + _.camelCase(field).substr(1);
        let fieldState = this[`model.${fieldName}`];
        return fieldState && !_.isEmpty(fieldState.errors);
    }

    get errors () {
        return _.flatMap(this, (val, key) => _.map(val.errors, er => er.errorMessage));
    }

    get isValid () {
        return _.every(this, (v, k) => _.isEmpty(v.errors));
    }
}

export class FieldState {
    constructor (options) {
        options = options || {};
        let fieldErrors = _.isArray(options.errors) ? options.errors : [];

        this.value = _.isUndefined(options.value) ? null : options.value;
        this.errors = _.map(fieldErrors, er => new FieldError(er));
    }
}

export class FieldError {
    constructor (options) {
        options = options || {};
        this.errorMessage = options.errorMessage || null;
        this.exception = options.exception || null;
    }
}

export class Region {
	constructor(options) {
        options = options || {};
		this.regionID = _.parseNumber(options.regionID, 0);
		this.regID = _.parseNumber(options.regID, 0);
		this.description = options.description || null;
		this.alarmBranchFilter = options.alarmBranchFilter || null;
		this.duplicateOrderSearchSetupID = options.duplicateOrderSearchSetupID || null;
		this.workflowProcessTemplateID = options.workflowProcessTemplateID || null;
		this.sellerPaysPercentageOwnersPremium = _.parseNumber(options.sellerPaysPercentageOwnersPremium, null);
		this.buyerPaysPercentageMortgagePremium = _.parseNumber(options.buyerPaysPercentageMortgagePremium, null);
		this.breakoutEndorsementHUD = _.parseNumber(options.breakoutEndorsementHUD, null);
		this.defaultLinesBetweenParagraphs = options.defaultLinesBetweenParagraphs || null;
		this.oneTimeUseCompanyNotification = options.oneTimeUseCompanyNotification || null;
		this.isCombineEndorsements = options.isCombineEndorsements || null;
		this.paperlessCloserBaseURL = options.paperlessCloserBaseURL || null;
		this.combinePrompt = options.combinePrompt || null;
	}
    get displayName() { return formatDisplayName(this.regID, this.description); }
}

const formatDisplayName = function(id, name) {
    let prefix = `${id} - `;
    if(name && name.indexOf(prefix) === 0) return name;

    let nameParts = [];
    if(_.gt(_.parseNumber(id, 0), 0)) nameParts.push(id);
    if(!_.isEmpty(name)) nameParts.push(name);
    return nameParts.join(" - ");
};

export class CompanyPickerModel {
    constructor(options){
        options = _.isString(options) ? { companyName: options } : options || {};
        this.roleTypeID = _.parseNumber(options.roleTypeID, null);
        this.companyID = _.parseNumber(options.companyID, null);
        this.companyName = options.companyName || options.company || options.name;

        this.contactID = _.parseNumber(options.contactID, null);
        this.contactName = options.contactName || options.contact;

        this.role = options.role || "";
        this.hasMore = false;
    }
    get isValid() { return _.isEmpty(this.companyName) || _.gt(this.companyID, 0); }
    get displayName() { return formatDisplayName(this.companyID, this.companyName); }
    matchesText(val) { return _.isEqual(this.companyName, val) || _.isEqual(this.displayName, val); }
}

export class SubdivisionDto {
    constructor(options){
        options = options || {};

        this.subdivisionID = _.parseNumber(options.subdivisionID, 0);
        this.name = options.name || "";
        this.notes = options.notes || null;
        this.plat = options.plat || null;
        this.patentDate = options.patentDate || null;
        this.regionID = _.parseNumber(options.regionID, null);
        this.region = options.region || null;
        this.isDirty = _.parseBool(options.isDirty, false);
        this.canDelete = _.parseBool(options.canDelete, true);
    }
    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Subdivision Name is required.");
        }
        if (_.isNil(this.regionID) || this.regionID == 0) {
            errorList.push("Region is required.");
        }

        return errorList;
    }
}

export class SurveyDto {
    constructor(options){
        options = options || {};

        this.surveyID = _.parseNumber(options.surveyID, 0);
        this.name = options.name || "";
        this.notes = options.notes || null;
        this.patentNumber = options.patentNumber || null;
        this.patentDate = options.patentDate || null;
        this.acres = _.parseNumber(options.acres, null);
        this.countyID = _.parseNumber(options.countyID, null);
        this.abstractNumber = _.parseNumber(options.abstractNumber, null);
        this.state = options.state || "";
        this.isDirty = _.parseBool(options.isDirty, false);
        this.canDelete = _.parseBool(options.canDelete, true);
    }
    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Abstract Name is required.");
        }

        return errorList;
    }

}
export class OrderStatusModel{
    constructor(options){
        options = options || {};

        this.id = _.parseNumber(options.id, null);
        this.name = options.name || '';
        this.selected = _.parseBool(options.selected, true);
    }
}
export class OrderFacetSearchOptions{
    constructor(options){
        options = options || {};

        this.name = options.name || '';
        this.skip = _.parseNumber(options.skip, 15);
        this.take = _.parseNumber(options.take, 0);
    }
}
export class OrderFacetSearchModel{
    constructor(options){
        options = options || {};

        this.activeFacet = options.activeFacet || 'Files';
        this.term = options.term || '';
        this.fileNumbers = _.map(options.fileNumbers, f => new FileNumberItemModel(f)) || [];
        this.fileNumbersTotalCount = _.parseNumber(options.fileNumbersTotalCount, 0);
        this.hasMoreFileNumbers = (this.fileNumbers.length < this.fileNumbersTotalCount);
        this.facetTotalCount = _.parseNumber(options.facetTotalCount, 0);
        this.facetCounts = options.facetCounts || [];
    }
}
export class OrderFacetDataSearchModel{
    constructor(options){
        options = options || {};

        this.activeFacet = options.activeFacet || 'Files';
        this.term = options.term || '';
        this.files = _.map(options.files, f => new OrdersFacetDto(f)) || [];
        this.buyersSellers = _.map(options.buyersSellers, b => new BuyersSellersFacetDto(b)) || [];
        this.properties = _.map(options.properties, p => new PropertiesFacetDto(p)) || [];
        this.loans = _.map(options.loans, l => new LoansFacetDto(l)) || [];
        this.policies = _.map(options.policies, p => new PoliciesFacetDto(p)) || [];
        this.facetTotalCount = _.parseNumber(options.facetTotalCount, 0);
        this.facetCounts = options.facetCounts || [];
    }
}
export class OrderFacetBase {
    constructor(options){
        options = options || {};

        this.ordersID = _.parseNumber(options.ordersID, null);
        this.fileNumber = options.fileNumber || null;
        this.status = options.status || null;
        this.region = options.region || null;
        this.branch = options.branch || null;
        this.hasAccess = _.parseBool(options.hasAccess);
    }
}
export class OrdersFacetDto extends OrderFacetBase {
    constructor(options){
        options = options || {};
        super(options);
        this.otherCompaniesFileNumber = options.otherCompaniesFileNumber || null;
        this.salesPrice = _.parseNumber(options.salesPrice, 0);
        //this.closeDate = _.isNil(options.closeDate) ? '' : moment(options.closeDate).format("L");
        this.closeDate = _.isNil(options.closeDate) ? '' : this.formatDateZeroPadded(options.closeDate);
        //this.fundDate = _.isNil(options.fundDate) ? '' : moment(options.fundDate).format("L");
        this.fundDate = _.isNil(options.fundDate) ? '' : this.formatDateZeroPadded(options.fundDate);
        this.closingAgent = options.closingAgent || null;
        this.underwriter = options.underwriter || null;
    }
}
export class PropertiesFacetDto extends OrderFacetBase {
    constructor(options){
        options = options || {};
        super(options);
        this.address = options.address || null;
        this.county = options.county || null;
    }
}
export class LoansFacetDto extends OrderFacetBase {
    constructor(options){
        options = options || {};
        super(options);
        this.loanNumber = options.loanNumber || null;
        this.amount = _.parseNumber(options.amount, null);
        this.lender = options.lender || null;
    }
}
export class PoliciesFacetDto extends OrderFacetBase {
    constructor(options){
        options = options || {};
        super(options);
        this.commitmentNumber = options.commitmentNumber || null;
        this.ownerPolicyNumber = options.ownerPolicyNumber || null;
        this.mortgagePolicyNumber = options.mortgagePolicyNumber || null;
        this.underwriter = options.underwriter || null;
    }
}

export class BuyersSellersFacetDto extends OrderFacetBase {
    constructor(options){
        options = options || {};
        super(options);
        this.role = options.role || null;
        this.name1 = options.name1 || null;
        this.name2 = options.name2 || null;
        this.formalName = options.formalName || null;
        this.businessName = options.businessName || null;
        this.displayName = options.displayName || null;
    }
}
export class FileNumberItemModel{
    constructor(options){
        options = options || {};
        this.ordersId = options.ordersId || 0;
		this.fileNumber = options.fileNumber || null;
    }
}
export class OrderHeaderModel{
    constructor(options){

        this.ordersId = options.ordersId || 0;
		this.gfno = options.gfno || null;
        this.status = options.status || null;
        this.hasMore = false;
    }
}
export class OrderSettingsViewModel {
    constructor (options) {
        options = options || {};
        this.ordersID = _.parseInt(options.ordersID) || null;
        this.fileNumber = options.fileNumber || null;
        this.isGlobal = _.parseBool(options.isGlobal, false);
        this.isEscrowLocked = _.parseBool(options.isEscrowLocked, false);
        this.isLocked = _.parseBool(options.isLocked, false);
        // this.escrowLockDate = _.parseDate(options.escrowLockDate);
        // this.escrowUnlockDate = _.parseDate(options.escrowUnlockDate);
        // this.lockedDate = _.parseDate(options.lockedDate);
        this.regionID = _.parseInt(options.regionID) || null;
        this.branchID = _.parseInt(options.branchID) || null;
        this.bankCompanyID = _.parseInt(options.bankCompanyID) || null;
     }
}

export class ReorderRequestChange {
    constructor(options){
        options = options || {};
        this.id = _.parseNumber(options.id, 0);
        this.orderId = _.parseNumber(options.orderId, 0);
        this.description = options.description || "";

        let singleOrdinal = _.parseNumber(options.ordinal, 0);
        this.fromOrdinal = _.parseNumber(options.fromOrdinal, 0) || singleOrdinal;
        this.toOrdinal = _.parseNumber(options.toOrdinal, 0) || singleOrdinal;
    }

    get ordinal() { return this.toOrdinal; }
    set ordinal(val) { this.toOrdinal = val; }
}

export class ListBoxItemModel {
    constructor(options) {
        options = options || {};
        // this.modelID = options.modelID >= 0 ? options.modelID : this.generateRandomId();
        this.itemID = options.itemID >= 0 ? options.itemID : this.generateRandomId();
        // this.itemID = options.itemID;
        this.itemName = options.itemName || '';
        this.isInactive = options.isInactive || false;
        this.isSelected = options.isSelected || false;
        this.isMarked = options.isMarked || false;
        this.sequence = options.sequence || 0;
    }

    generateRandomId() {
        return Math.floor(Math.random() * (10000000000 - 1 + 1) ) + 1;
    }
}

export class BuyerSellerOverrideModel {
    constructor(options) {
        options = options || {} ;
        this.id = options.id || '';
        this.role = options.role || '';
        this.entityId = options.entityId || 0;
        this.displayName = options.displayName || '';
        this.closeDate = options.closeDate || null;
        this.closeTime = DateTime.fromISO(options.closeTime).isValid ? DateTime.fromISO(options.closeTime, {zone: "utc"}).toLocal().toISO() : null;
        this.closeTimeUtc = options.closeTime || null;
        this.defaultCloseDate = options.defaultCloseDate || null;
        this.defaultCloseTime = DateTime.fromISO(options.defaultCloseTime).isValid ? DateTime.fromISO(options.defaultCloseTime, {zone: "utc"}).toLocal().toISO() : null;
        this.defaultCloseTimeUtc = options.defaultCloseTime || null;
        this.closingAddress1 = options.closingAddress1 || null;
        this.closingAddress2 = options.closingAddress2 || null;
        this.closingCity = options.closingCity || null;
        this.closingState = options.closingState || null;
        this.closingZip = options.closingZip || null;
        this.displayClosingLocation = options.displayClosingLocation || null;
        this.defaultClosingAddress1 = options.defaultClosingAddress1 || null;
        this.defaultClosingAddress2 = options.defaultClosingAddress2 || null;
        this.defaultClosingCity = options.defaultClosingCity || null;
        this.defaultClosingState = options.defaultClosingState || null;
        this.defaultClosingZip = options.defaultClosingZip || null;
        this.isOverridden = _.parseBool(options.IsOverridden, false) || _.parseBool(options.isOverridden, false) || false;
    }

    get isValid() { return this.validationErrors.length === 0; }
    get validationErrors() {
        let errorList = [];

        return errorList;
    }
}

export class TabAlert {
    constructor(options) {
        options = options || {};
        this.alertCount = _.parseNumber(options.alertCount, 0);
        this.alertType = _.parseNumber(options.alertType, AlertSeverity.Error);
        this.alertMessages = options.alertMessages || [];
    }
}

export class SellerProceeds {
    constructor(options) {
        options = options || {};
        this.id = _.parseNumber(options.id, 0);
        this.orderId = options.orderId;
        this.userAccountId = _.parseNumber(options.userAccountId, 0);
        this.proceedsType = _.parseNumber(options.proceedsType, 0);
        this.checkAddress = options.checkAddress || '';
        this.checkCity = options.checkCity || '';
        this.checkState = options.checkState || '';
        this.checkZip = options.checkZip || '';
        this.wireFirstName = options.wireFirstName || '';
        this.wireMiddleName = options.wireMiddleName || '';
        this.wireLastName = options.wireLastName || '';
        this.wireNameOnAcc = options.wireNameOnAcc || '';
        this.wireBankName = options.wireBankName || '';
        this.wireRoutingNo = options.wireRoutingNo || '';
        this.wireAccNo = options.wireAccNo || '';
    }
}

export class AddressDto {
    constructor(options) {
        options = options || {};
        this.line1 = options.line1 || '';
        this.line2 = options.line2 || '';
        this.city = options.city || '';
        this.state = _.get(options, "state.abbreviation", options.state) || '';
        this.zipCode = options.zipCode || '';
        this.county = options.county || '';
    }
}