
const RegEx = {
    Phone: new RegExp(/^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/),
    Email: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    SSN: new RegExp(/^([0-9]{3})-([0-9]{2})-([0-9]{4})$/),
    TaxID: new RegExp(/^([0-9]{2})-([0-9]{7})$/),
    FileName: new RegExp(/^(?!\.)(?!com[0-9]$)(?!con$)(?!lpt[0-9]$)(?!nul$)(?!prn$)[^|*?\\:<>/$"]*[^.|*?\\:<>/$"]+$/, "g"),
    PropertyTaxID: new RegExp(/^[a-zA-Z0-9-]*$/),
};

export default RegEx;