const CLIP_CONTENT_TYPES = {
    plainText: "text/plain",
    htmlText: "text/html"
};
export default class RqClipboardManager {
    constructor(options) {
        this.maxHistoryLength = _.getNumber(options, "maxHistoryLength", null) || 10;
        this.history = [];
    }

    static get contentTypes() { return CLIP_CONTENT_TYPES; }

    get hasSecureContext() { return document.location.protocol === "https:"; }

    get lastCopiedContent() {
        if(_.isEmpty(this.history)) return null;
        return _.last(this.history);
    }

    parseContent(content, contentType) {
        return {
            [CLIP_CONTENT_TYPES.plainText]: contentType === CLIP_CONTENT_TYPES.plainText ? content : _.htmlToText(content),
            [CLIP_CONTENT_TYPES.htmlText]: contentType === CLIP_CONTENT_TYPES.htmlText ? content : null
        };
    }

    execAction(action, value) {
        switch(action) {
            case "copy-text": return this.copyContent(this.parseContent(value, "text/plain"));
            case "copy-html": return this.copyContent(this.parseContent(value, "text/html"));
            case "copy-last": return this.copyContent();
            case "clear-history": return this.clearHistory();
        }
    }

    execCopy(content, contentType) {
        let clipItem = this.parseContent(content, contentType);
        return this.copyContent(clipItem);
    }

    getClipboardData(item) {
        let clipData = [];
        let type = _.isEmpty(item["text/html"]) ? "text/plain" : "text/html";
        return [ new window.ClipboardItem({ [type]: new Blob([item[type]], { type }) }) ];

        // _.forEach(item, (content,type) => {
        //     if(_.isEmpty(content)) return;
        //     let clipItem = new window.ClipboardItem({ [type]: new Blob([content], { type }) });
        //     clipData.push(clipItem);
        // });
        // return clipData;
    }

    copyContent(item) {
        const self = this;
        if(!this.hasSecureContext) return this.clipboardUnavailable("ClipboardApi requires a secure context");
        if(_.isEmpty(item) && _.isEmpty(this.lastCopiedContent)) return Promise.resolve(false);
        let clipItem = item || this.lastCopiedContent;

        const clipboardPermissions = _.get(window, "navigator.permissions", null);
        const clipboardApi = _.get(window, "navigator.clipboard", null);

        if(_.isNil(clipboardPermissions) || _.isNil(clipboardApi) || _.isNil(window.ClipboardItem))
            return self.clipboardUnavailable("Clipboard API unavailable");

        return clipboardPermissions.query({ name: "clipboard-write" })
            .then(result => {
                if(result.state !== "granted")
                    return self.clipboardUnavailable("window.navigator \"clipboard-write\" access denied");

                let clipData = self.getClipboardData(clipItem);
                return clipboardApi.write(clipData);
            })
            .then(() => this.finishCopyAction(clipItem))
            .catch(err => {
                console.error(err);
                return Promise.resolve(false);
            });
    }

    finishCopyAction(item) {
        let success = !_.isNil(item);
        if(success && !_.isEqual(item, this.lastCopiedContent)) this.history.push(item);
        if(this.history.length <= this.maxLength) return success;
        this.history.shift();
        return success;
    }

    clipboardUnavailable(msg) {
        console.warn(`RqClipboardManager :: ${msg}; Use document.execCommand instead.`);
        return Promise.resolve(false);
    }

    clearHistory() {
        this.history = [];
    }
}