/****************************************************************************************************************************
    Directive:
         v-focus
    Usage:
         Add "v-focus" to a component to focus the component element or child element based on modifiers.  If the desired focus
         element is a child element of component this directive is utilized on, use the corresponding directive
         modifier to focus the child element rather than the component element.  Available modifiers are "input", "select", and
         "textarea".
    Example:
         <rq-input-number v-model="someValue" v-rq-focus.input />
****************************************************************************************************************************/

const getFocusOptions = function(binding, defaultEnabled) {
    let defaultOptions = {
        enabled: true,
        delay: 0,
        tagName: null,
        selector: null
    };
    let options = _.isObject(binding.value)
            ? { ...defaultOptions, ...binding.value }
            : { ...defaultOptions, enabled: _.getBool(binding, "value", defaultEnabled) };

    if(!_.isEmpty(binding.modifiers)) {
        if(_.getBool(binding, "modifiers.input")) options.tagName = "INPUT", options.selector = "input:not([type=hidden]";
        if(_.getBool(binding, "modifiers.select")) options.tagName = "SELECT", options.selector = "select";
        if(_.getBool(binding, "modifiers.textarea")) options.tagName = "TEXTAREA", options.selector = "textarea";
        if(_.getBool(binding, "modifiers.button") || _.getBool(binding, "modifiers.btn")) options.tagName = "button", options.selector = "button";
    }
    return options;
};

const focusTargetElement = (el, options) => _.delay(() => {
    if(!options.enabled) return;

    let target = (_.isNil(options.tagName) || _.isNil(options.selector) || el.tagName === options.tagName)
        ? el
        : el.querySelector(options.selector);

    if(!target) return;

    target.focus();
}, options.delay);

export default {
    mounted(el, binding) {
        let options = getFocusOptions(binding, true);
        focusTargetElement(el, options);
    },
    updated(el, binding) {
        let options = getFocusOptions(binding, false);
        focusTargetElement(el, options);
    }
};