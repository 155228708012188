<template>
    <div class="rq-container content-wrapper pt-3">
        <p>{{message}}</p>
        <p>Logging out in {{ secondsRemaining }} {{'second' + (secondsRemaining == 1 ? '' : 's')}}...</p>
    </div>
</template>
<script setup>
    import { ref, computed, onMounted, onBeforeUnmount } from "vue";
    import { useStore } from "vuex";
    import { Interval } from "@/shared/utilities";

    const props = defineProps({
        remainingMs: { type: Number, default: 60000 }
    });

    const emit = defineEmits(["action"]);

    const store = useStore();

    const intervalName = "idle-timeout";
    const refreshInterval = 1000;

    const elapsedTime = ref(0);
    const cleared = ref(false);
    const hasFocus = ref(false);
    const timerExpired = ref(false);

    const tokenExpirationTimeout = computed(() => store.getters.tokenExpirationTimeout);
    const secondsRemaining = computed(() => (props.remainingMs - elapsedTime.value) / 1000);

    const message = computed(() => {
        let idleMs = _.parseNumber(tokenExpirationTimeout.value - props.remainingMs, 0) || tokenExpirationTimeout.value;
        let idleMinutes = Math.ceil(idleMs / 1000 / 60);
        return idleMinutes <= 0
            ? `You have left this application idle.`
            : `You have been idle for ${idleMinutes} minute${idleMinutes == 1 ? '' : 's'}.`;
    });

    function emitActivityCheck() {
        emit("action", {
            name: "activity-check",
            data: {
                hasFocus: hasFocus.value,
                timerExpired: timerExpired.value
            }
        });
    }

    function ticker() {
        elapsedTime.value += refreshInterval;
        if (secondsRemaining.value > 0) return;
        timerExpired.value = true;
        if(!hasFocus.value) clearInterval();
        emitActivityCheck();
    }

    const onWinBlur = () => hasFocus.value = false;
    function onWinFocus() {
        hasFocus.value = true;
        emitActivityCheck();
    }

    function addWindowEventListeners() {
        window.addEventListener("blur", onWinBlur);
        window.addEventListener("focus", onWinFocus);
    }

    function removeWindowEventListeners() {
        window.removeEventListener("blur", onWinBlur);
        window.removeEventListener("focus", onWinFocus);
    }

    function clearInterval() {
        if(cleared.value) return;
        cleared.value = true;
        Interval.clear(intervalName);
    }

    onMounted(() => {
        hasFocus.value = document?.hasFocus();
    });

    onBeforeUnmount(() => {
        removeWindowEventListeners();
        clearInterval();
    });

    Interval.make(intervalName, ticker, refreshInterval, false, 0);
    addWindowEventListeners();

    defineExpose({ clearInterval });
</script>