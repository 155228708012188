// root
export const SET_BUSY = "setBusy";
export const SET_PAGE = "setPage";
export const SET_BUSY_QUEUE = "rqBusy_SetQueue";
export const SET_BUSY_MESSAGE = "rqBusy_SetMessage";
export const SET_SAVED_ROUTE = "setSavedRoute";

export const ROOT_MUTATIONS = {
    SET_BUSY,
    SET_PAGE,
    SET_BUSY_QUEUE,
    SET_BUSY_MESSAGE,
    SET_SAVED_ROUTE
};

// configuration
export const SET_CURRENT_ORDER_UNIQUE_ID = "setCurrentOrderUniqueId";
export const CONFIGURATION_MUTATIONS = {
    SET_CURRENT_ORDER_UNIQUE_ID
}

// Consumer
export const CONSUMER_SET_ORDER_UNIQUE_ID = "consumerSetOrderUniqueId"
export const CONSUMER_ORDER_MUTATIONS = {
    CONSUMER_SET_ORDER_UNIQUE_ID,
}

// authorization
export const PURGE_AUTH = "purgeAuth";
export const SET_LOGOUT_USER = "setLogoutUser";
export const SET_USER_EMAIL = "setUserEmail";
export const SET_TOKEN = "setToken";
export const SET_USER = "setUser";
export const SET_USER_AGENT = "setUserAgent";
export const SET_SITE = "setSite";
export const SET_USER_ERROR = "setUserError";
export const SET_USER_STATUS_CODE = "setUserStatusCode";
export const SET_VIEW_AS_UNIQUEID = "setViewAsUniqueId";
export const SET_VIEW_AS_EMAIL = "setViewAsEmail";
export const SET_WALK_ME_VAR_REFERENCE = "setWalkMeVarReference";

export const AUTH_MUTATIONS = {
    PURGE_AUTH,
    SET_LOGOUT_USER,
    SET_USER_EMAIL,
    SET_TOKEN,
    SET_SITE,
    SET_USER,
    SET_USER_AGENT,
    SET_USER_ERROR,
    SET_USER_STATUS_CODE,
    SET_VIEW_AS_UNIQUEID,
    SET_VIEW_AS_EMAIL,
    SET_WALK_ME_VAR_REFERENCE
};

// order
export const SET_ORDER = "setOrder";
export const SET_CURRENT_ORDER_ID = "setCurrentOrderId";
export const SET_FILE_ORDER = "SetFileOrder";
export const CLEAR_CURRENT_ORDER = "clearCurrentOrder"
export const ORDER_MUTATIONS = {
    SET_ORDER,
    SET_CURRENT_ORDER_ID,
    SET_FILE_ORDER,
    CLEAR_CURRENT_ORDER,
}

export const SET_LANDING_PAGE = "setLandingPage";
export const LANDINGPAGE_MUTATIONS = {
    SET_LANDING_PAGE,
}



// modules
export const SET_STAFF = "setStaff";
export const SET_REALM = "setRealm";
export const MODULE_MUTATIONS = {
    SET_STAFF,
    SET_REALM,
};

export const SET_USERS = "setUsers";
export const SET_EDIT_USER = "setEditUser";
export const SET_FILE_ADMIN = "setFileAdmin";
export const SET_INTERNAL_ADMIN = "setInternalAdmin";
export const SET_SITE_ADMIN = "setSiteAdmin";
export const SET_INIT_ADMIN = "setInitAdmin";
export const SET_EDIT_KEYCLOAK_USER_ACCOUNT_ID = "setEditKeycloakUserAccountId";
export const SET_ADMIN_COUNT = "setAdminCount";
export const SET_FORCE_CHANGE_PASSWORD = "forceChangePassword";
export const SET_ORIGINAL_STATUS = "setOriginalStatus";
export const USER_MUTATIONS = {
    SET_USERS,
    SET_EDIT_USER,
    SET_FILE_ADMIN,
    SET_INTERNAL_ADMIN,
    SET_SITE_ADMIN,
    SET_EDIT_KEYCLOAK_USER_ACCOUNT_ID,
    SET_ADMIN_COUNT,
    SET_INIT_ADMIN,
    SET_FORCE_CHANGE_PASSWORD,
    SET_ORIGINAL_STATUS
}

export const SET_ROLES = "setRoles";
export const ROLES_MUTATIONS = {
    SET_ROLES
}

// export const SET_PERMISSIONS = "setPermissions";
// export const PERMISSIONS_MUTATIONS = {
//     SET_PERMISSIONS
// }

export const SET_IS_SAVING = "isSaving";
export const SET_SAVED = "saved";
export const SET_ACTION = "action";
export const SET_ERROR = "setError";
export const SET_ERROR_MESSAGE = "setErrorMessage";
export const COMMON_MUTATIONS = {
    SET_IS_SAVING,
    SET_SAVED,
    SET_ACTION,
    SET_ERROR,
    SET_ERROR_MESSAGE
}