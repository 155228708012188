import { SET_FILE_ORDER, CLEAR_CURRENT_ORDER } from '../mutations';
// import {GET_FILE_ORDER,GET_FILE_PARTIES} from '../actions';
// import helpers from '../helpers';
import { CONFIGURATION_MUTATIONS } from "../mutations";

// import UserAccountApi from '@/api/useraccount';
// import OrderApi from '@/api/order';

const state = {
    fileOrder: {},
    order: {},
};

const getters = {
    // currentOrderId: state => state.currentOrderId,
    // order: state => state.order,
    fileOrder: state => state.fileOrder,
}

const mutations = {
    // [SET_ORDER] (state, value) {
    //     state.order = value;
    // },
    // [SET_CURRENT_ORDER_ID] (state,value) {
    //     state.currentOrderId = value;
    // },
    [SET_FILE_ORDER] (state, value) {
        state.fileOrder = value;
    },
}

//context = { state, rootState, getters, rootGetters, dispatch, commit }

const actions = {
    // [SET_FILE_ORDER] (context, data) {
    //     var userUniqueId = context.rootGetters.user.uniqueId;
    //     var orderUniqueId = data.orderUniqueId;

    //     return UserAccountApi
    //         .saveCurrentOrder(userUniqueId, orderUniqueId)
    //         .then((response) => {
    //             return context.dispatch(CONFIGURATION_MUTATIONS.SET_CURRENT_ORDER_UNIQUE_ID, orderUniqueId, { root: true });
    //         })
    //         .then((response) => {
    //             return OrderApi.getFileOrder(orderUniqueId);
    //         })
    //         .then((response) => {
    //             context.commit(SET_FILE_ORDER, response);
    //         })
    //         .catch((error) => {
    //             context.commit(SET_FILE_ORDER, null);
    //         })
    // },
    [CLEAR_CURRENT_ORDER] (context) {
        return context
                .dispatch(CONFIGURATION_MUTATIONS.SET_CURRENT_ORDER_UNIQUE_ID, null, { root: true })
                .then((response) => {
                    context.commit(SET_FILE_ORDER, {});
                });
    }

}

export default { state, getters, actions, mutations };