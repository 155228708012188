import DeepDiff from "deep-diff";

const diffToAuditChange = diff => {
    let isArrayDiff = diff.kind === "A";
    let field = isArrayDiff ? `${diff?.path?.join('.')}.${diff?.index}` : diff?.path?.join('.');
    let oldValue = _.get(diff, isArrayDiff ? "item.lhs" : "lhs", null);
    let newValue = _.get(diff, isArrayDiff ? "item.rhs" : "rhs", null);

    if(_.isNullOrEmpty(oldValue) && _.isNullOrEmpty(newValue)) return null;

    return { "name": field, "old": oldValue, "new": newValue};
};
const getAuditChanges = (oldVal, newVal, ignoreFields=[]) => {
    let auditChanges = [];
    let diffs = DeepDiff.diff(oldVal, newVal);
    _.each(diffs, diff => {
        let change = diffToAuditChange(diff);
        if(_.isNil(change) || _.some(ignoreFields, field => _.includes(diff.path, field))) return;
        auditChanges.push(_.merge(change, { system: false }));
    });
    return auditChanges;
};

export default {
    install (app) {
        app.mixin({
            methods: {
                diffToAuditChange,
                getAuditChanges
            }
        });
    }
};

export const useDeepDiff = () => ({
    diffToChange: diffToAuditChange,
    getChanges: getAuditChanges
});