import {SET_CURRENT_ORDER_UNIQUE_ID} from '../mutations';

const state = {
    currentOrderUniqueId: null
};

const getters = {
    currentOrderUniqueId: state => state.currentOrderUniqueId
}

const mutations = {
    [SET_CURRENT_ORDER_UNIQUE_ID] (state, value) {
        state.currentOrderUniqueId = value;
    }
}

const actions = {
    [SET_CURRENT_ORDER_UNIQUE_ID] (context, data) {

        return new Promise((resolve) => {
            context.commit(SET_CURRENT_ORDER_UNIQUE_ID, data);
            resolve();
        });
    },
}

export default { state, getters, actions, mutations };