
// Import global application styles
import "../scss/style.scss";

import { createApp, h } from "vue";
import { createPinia } from "pinia";
import { createAppInstaller } from "./AppInstaller";
import { appConfig, APP_ISSUE_CODE } from "./AppConfig";
import { createFaLibrary, createFaLibraryRestricted } from "./FaInstaller";

// Authentication
import { SET_USER_EMAIL, SET_REALM, AUTH_MUTATIONS, SET_ERROR, SET_CURRENT_ORDER_UNIQUE_ID, SET_USER, SET_USER_AGENT, SET_SITE, SET_WALK_ME_VAR_REFERENCE } from './store/mutations';

import { GlobalEventManager } from "@/app.events";
import { UAService, Identity } from "@/shared/services";
import UAParser from "ua-parser-js";

// Import root component, router, & store
import App from "./App";
import AppRestricted from "./AppRestricted";
import router from "./router";
import store from "./store";
// import { INITIALIZE_SESSION } from "./store/actions";

const setWalkMeVariables = (user) => {
    const userLogin = _.get(user, "emailAddress", null) || "LOGIN_UNDEFINED";
    const tenantName = _.split(_.get(window, "location.host"), '.')[0];
    const r2c_userID = `${userLogin}:${tenantName}`;
    const r2c_userRole = _.get(user, "userRole[0].name");

    window.r2c_userID = r2c_userID;
    window.r2c_userRole = r2c_userRole;

    return { r2c_userID, r2c_userRole };
};

const initApp = async () => {
    let authUrl = location.protocol + "//keycloak." + location.hostname.substr(location.hostname.indexOf('.') + 1) + '/auth';
    let realm = "global";

    store.commit(SET_REALM, realm);
    let hostname = location.hostname;
    let tenantName = _.toLower(hostname.substring(0, hostname.indexOf(".")));
    return await Identity.initialize({
        url: authUrl,
        clientId: `${tenantName}-ui`,
        realm: realm,
        logoutRedirectUri: location.origin,
    });
};

const loadRestrictedApp = (issueCode=APP_ISSUE_CODE.UNHANDLED) => {
    createFaLibraryRestricted();
    const app = createApp({
        render: () => h(AppRestricted, { issueCode })
    });
    app.mount("#app");
};

const loadFullApp = async data => {
    createFaLibrary();

    const app = createApp(App);
    const pinia = createPinia();

    app.use(appConfig);
    app.use(pinia);
    app.use(store);
    //TODO move to store
    const userInfo = data.sessionInfo.userInfo;
    const uaParser = new UAParser(window.navigator.userAgent);
    const siteInfo = data.sessionInfo.siteInfo;
    store.commit(SET_USER_EMAIL, userInfo.emailAddress);
    store.commit(AUTH_MUTATIONS.SET_TOKEN, data.token);
    store.commit(SET_USER_AGENT, uaParser.getResult());
    localStorage.setItem('setLastLogin', false);
    store.commit(SET_USER, userInfo);
    store.commit(SET_SITE, siteInfo);
    store.commit(SET_CURRENT_ORDER_UNIQUE_ID, userInfo.currentOrderUniqueId || null);
    let walkMeVars = setWalkMeVariables(userInfo);
    store.commit(AUTH_MUTATIONS.SET_WALK_ME_VAR_REFERENCE, walkMeVars);
    //TODO move to store
    app.use(router);
    router.app = app;

    app.use(createAppInstaller(router));

    await router.isReady()
    app.mount("#app");

    return true;
};

const createReady2closeApp = async () => {
    let issueCode = "";

    try {
        if(!UAService.isDeviceSupported()) {
            issueCode = APP_ISSUE_CODE.UNSUPPORTED_DEVICE;
        }
        else {
            //get environment info and authorize user
            GlobalEventManager.busyStart();
            let sessionInfo = await initApp();
            if(sessionInfo?.status === APP_ISSUE_CODE.UNAUTHORIZED) return;
            if(sessionInfo?.isValidUser) {
                let success = await loadFullApp(sessionInfo);
                //if success is true, return since there's nothing more to do
                if(success) return;
            }
            else {
                issueCode = sessionInfo?.status;
            }
        }
    }
    catch(err) {
        issueCode = APP_ISSUE_CODE.UNHANDLED;
        console.error(err);
    }

    loadRestrictedApp(issueCode);
};

createReady2closeApp();