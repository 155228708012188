import Store from '../store';
import {SET_ERROR, SET_ERROR_MESSAGE} from './mutations';

export const handleError = function (error) {
    Store.commit(SET_ERROR, true);
    if (error.response) {
        Store.commit(SET_ERROR_MESSAGE,error.data);
      } else if (error.request) {
        Store.commit(SET_ERROR_MESSAGE, error.request);
      } else {
        Store.commit(SET_ERROR_MESSAGE, error.message);
      }
}

// export const getJSONWithParm = function(controller,action,parameters,mutation) {
//     Store.commit(SET_ERROR,false);
//     Store.commit(SET_STATUS_CODE,"");
//     Store.commit(SET_ERROR_MESSAGE,"");

//     // let uri = location.origin + "/api/" + api + "/";
//     // if (method != "") {
//     //     uri = uri + method + "/";
//     // }

//     // uri = uri + parm;

//     return GeneralApi.get(controller, action, parameters)
//         .then((result) => {
//             Store.commit(SET_STATUS_CODE,"200");
//             Store.commit(mutation ,result)
//         })
//         .catch(error => {
//             if (error.response.status == "204")
//             {
//                 Store.commit(mutation,"{}");
//             }
//             else {
//                 handleError(error);
//             }
//         });
// }

export default {
    // getJSONWithParm,
    handleError
}