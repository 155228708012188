import { AUTH_MUTATIONS } from '../mutations';
import { AUTH_ACTIONS, USER_PAGE_ACTION } from '../actions';
import { JwtService, Identity } from "../../shared/services";

const state = {
    isAuthenticated: false,
    userEmail: null,
    token: null,
    user: {},
    userAgent: {},
    isMobile: false,
    site: {},
    viewAsUniqueId: null,
    viewAsEmail: null,
    initializationTime: new Date(),
    walkMeVarReference: {}
};

const getters = {
    email: state => state.userEmail,
    token: state => state.token,
    user: state => state.user,
    userAgent: state => state.userAgent,
    isMobile: state => state.userAgent?.device?.type == "mobile" || state.userAgent?.device?.type == "tablet",
    site: state => state.site,
    viewAsUniqueId: state => state.viewAsUniqueId,
    viewAsEmail: state => state.viewAsEmail,
    impersonation: state => !!state.viewAsEmail,
    timeZone: (state) => {
        var currentDate = state.initializationTime.toString();
        var tz = currentDate.substring(currentDate.indexOf("(") + 1, 100);
        tz = tz.substring(0, tz.length - 1);
        return [tz, Intl.DateTimeFormat().resolvedOptions().timeZone].join(",");
    },
    isAuthenticated: state => !!state.token
}

const mutations = {
    [AUTH_MUTATIONS.SET_USER_EMAIL] (state, value) {
        state.userEmail = value;
    },
    [AUTH_MUTATIONS.SET_TOKEN] (state, value) {
        state.token = value;
    },
    [AUTH_MUTATIONS.SET_SITE] (state, value) {
        state.site = value;
    },
    [AUTH_MUTATIONS.SET_USER] (state, value) {
        state.user = value;
    },
    [AUTH_MUTATIONS.SET_USER_AGENT] (state, value) {
        state.userAgent = value;
    },
    [AUTH_MUTATIONS.SET_VIEW_AS_UNIQUEID] (state, value) {
        state.viewAsUniqueId = value;
    },
    [AUTH_MUTATIONS.SET_VIEW_AS_EMAIL] (state, value) {
        state.viewAsEmail = value;
    },
    [AUTH_MUTATIONS.SET_WALK_ME_VAR_REFERENCE] (state, value) {
        state.walkMeVarReference = value;
    },
    [AUTH_MUTATIONS.PURGE_AUTH] (state) {
        state.user = {};
        state.site = {};
        state.error = null;
        JwtService.destroyToken();

        // Keycloak
        state.token = "";
        // state.identityTokenParsed = {};
        state.isAuthenticated = false;
    }
}

const actions = {
    [AUTH_ACTIONS.CLEAR_VIEW_AS] (context) {
        // Set the internal value (via commit).
        context.commit(AUTH_MUTATIONS.SET_VIEW_AS_UNIQUEID, null);
        context.commit(AUTH_MUTATIONS.SET_VIEW_AS_EMAIL, null);

        // Re-dispatch the login action.
        return context.dispatch(USER_PAGE_ACTION.LOGIN_UI_USER, state.userEmail);

        // Note, when this is complete, we should re-route the user to the home page.
        // This re-routing will be handled by the UI and NOT the store.
    },
    async [AUTH_ACTIONS.LOGOUT] (context, { redirectUrl=null, caller=null }={ redirectUrl: null, caller: null }) {
        //RQO-28613 - TG - Bypass logout action if attempting to force a logout when an error occurs refreshing the token.
        if(caller === "IdentityService.refreshToken") return;

        try {
            // This is true when the user is validated through Identity AND tenant.
            let isSessionValid = context.getters.isSessionValid;
            if(!isSessionValid) return true;
        }
        finally {
            context.commit(AUTH_MUTATIONS.PURGE_AUTH);
            Identity.logOut(redirectUrl);
        }
        return true;
    },
    [AUTH_ACTIONS.SET_VIEW_AS] (context, data) {
        // Set the internal value (via commit).
        context.commit(AUTH_MUTATIONS.SET_VIEW_AS_UNIQUEID, data.uniqueId);
        context.commit(AUTH_MUTATIONS.SET_VIEW_AS_EMAIL, data.emailAddress);

        // Re-dispatch the login action.
        return context.dispatch(USER_PAGE_ACTION.LOGIN_UI_USER, state.viewAsEmail);

        // Note, when this is complete, we should re-route the user to the home page.
        // This re-routing will be handled by the UI and NOT the store.
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};