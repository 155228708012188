import { SET_ERROR, SET_CURRENT_ORDER_UNIQUE_ID, SET_USER, SET_SITE } from '../mutations';
import { LOGIN_UI_USER } from '../actions';
import LandingPageApi from '@/api/landingpage';
import helpers from '../helpers';

const actions = {
    [LOGIN_UI_USER]({ commit, dispatch }, email) {
        commit(SET_ERROR, false);
        if (_.isNullOrEmpty(email)) {
            commit(SET_USER, null);
            sessionStorage.setItem('setLastLogin', true);
            return;
        }
        let setLastLogin = sessionStorage.getItem('setLastLogin') || false;

        return LandingPageApi.getLandingPage(setLastLogin)
            .then((result) => {
                sessionStorage.setItem('setLastLogin', false);
                commit(SET_USER, result.userInfo);
                commit(SET_SITE, result.siteInfo);
                //commit(SET_CURRENT_ORDER_UNIQUE_ID, null);
            })
            .catch(error => {
                commit(SET_USER, null);
                commit(SET_CURRENT_ORDER_UNIQUE_ID, null);
                helpers.handleError(error);
            });
    },
}

export default {
    actions
};