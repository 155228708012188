import { ref, computed, nextTick } from "vue";
import { useStore } from "vuex";
import { DateTime, Duration } from "luxon";
import { Identity, LogService } from "@/shared/services";
import { useRqDialog } from "../plugins/composables";
import { IdleTimeoutDialog } from "@/shared/components";
import { AUTH_ACTIONS } from "@/store/actions";
import IdleJs from "idle-js";

const DEFAULT_MS_REMAINING = 60000;
const LAST_ACTIVE_STORAGE_KEY = "hzn-last-active-timestamp";

export function useIdleJs({ notifyMsRemaining=0 }) {
    const store = useStore();
    const { openDialog, closeDialog } = useRqDialog();

    let idleJsInstance;
    const isInitialized = ref(false);
    const isIdle = ref(false);
    const isRunning = ref(false);
    const isTabMonitoring = ref(false);
    const isBrowserTabFocused = ref(false);
    const browserTabLastActive = ref(DateTime.now().toUnixInteger());
    const idleDialogId = ref(null);
    const isAuthenticated = computed(() => store.state.authentication.isAuthenticated);
    const tokenExpirationTimeout = computed(() => store.getters.tokenExpirationTimeout);
    const timeoutDisplay = computed(() => Duration.fromMillis(tokenExpirationTimeout.value).toFormat("m'm' s's'"));
    const idleDialogActive = computed(() => !_.isNil(idleDialogId.value));

    const remainingMs = notifyMsRemaining || DEFAULT_MS_REMAINING;

    const logInfo = (msg, obj={}) => LogService.logInfo(`useIdleJs().${msg}`, {
        isInitialized: isInitialized.value,
        isIdle: isIdle.value,
        isRunning: isRunning.value,
        isAuthenticated: isAuthenticated.value,
        browserTabLastActive: browserTabLastActive.value,
        idleDialogActive: idleDialogActive.value,
        tokenExpirationTimeout: timeoutDisplay.value,
        notifyMsRemaining: remainingMs,
        ...obj
    });

    const getBrowserLastActive = () => _.parseNumber(localStorage.getItem(LAST_ACTIVE_STORAGE_KEY), 0);
    const setBrowserLastActive = (val=null) => {
        let timestamp = _.isNil(val) ? DateTime.now().toUnixInteger() : val;
        localStorage.setItem(LAST_ACTIVE_STORAGE_KEY, timestamp);
        return timestamp;
    };
    const updateBrowserLastActive = () => {
        if(!isBrowserTabFocused.value) return;
        browserTabLastActive.value = setBrowserLastActive();
    };

    function startIdleJs() {
        logInfo("startIdleJs()");
        if(!isInitialized.value) initIdleJs();
        if(isRunning.value) return;
        idleJsInstance?.start();
        isRunning.value = true;
    }

    function stopIdleJs() {
        logInfo("stopIdleJs()");
        if(!isRunning.value || !isInitialized.value) return;
        idleJsInstance?.stop();
        isRunning.value = false;
    }

    async function restartIdleJs(refreshToken=true) {
        logInfo("restartIdleJs()");
        closeAppIdleDialog();
        if(isInitialized.value) {
            if(isRunning.value)
                stopIdleJs();
            idleJsInstance?.reset();
        }
        await nextTick();
        startIdleJs();
        isIdle.value = false;
        updateBrowserLastActive();
        if(!refreshToken) return;
        Identity.refreshToken();
    }

    function initIdleJs() {
        if(isRunning.value || !_.isNil(idleJsInstance)) {
            initTabMonitoring();
            return;
        }
        logInfo("initIdleJs()");

        let lastActive = getBrowserLastActive();
        isBrowserTabFocused.value = _.parseBool(document?.hasFocus());
        if(isBrowserTabFocused.value || _.isNil(lastActive)) {
            setBrowserLastActive();
        }

        idleJsInstance = new IdleJs({
            idle: tokenExpirationTimeout.value - remainingMs,
            onIdle() {
                logInfo("IdleJs.onIdle");
                isIdle.value = true;
                if(!isAuthenticated.value) return;
                openAppIdleDialog();
            },
            startAtIdle: false
        });
        isInitialized.value = true;
        initTabMonitoring();
    }

    function initTabMonitoring() {
        if(isTabMonitoring.value) return;

        logInfo("initTabMonitoring()");

        isTabMonitoring.value = true;
        //ensure we don't end up with multiple listeners for each event
        window.removeEventListener("blur", onWindowBlur);
        window.removeEventListener("focus", onWindowFocus);

        window.addEventListener("blur", onWindowBlur);
        window.addEventListener("focus", onWindowFocus);
    }

    function onWindowBlur() {
        isBrowserTabFocused.value = false;
        browserTabLastActive.value = setBrowserLastActive();
        logInfo("onWindowBlur()");
    }

    function onWindowFocus() {
        logInfo("onWindowFocus()");
        isBrowserTabFocused.value = true;
        if(idleDialogActive.value) return;
        let lastActive = getBrowserLastActive();
        if(browserTabLastActive.value < lastActive) {
            restartIdleJs();
            return;
        }
        if(isIdle.value) {
            openAppIdleDialog();
            return;
        }
        if(idleDialogActive.value) return;
        restartIdleJs(false);
    }

    function openAppIdleDialog() {
        if(!isIdle.value || !isAuthenticated.value || idleDialogActive.value) return;

        logInfo("openAppIdleDialog()");
        stopIdleJs();

        const continueSession = () => {
            idleDialogId.value = null;
            restartIdleJs();
            return true;
        };

        idleDialogId.value = openDialog({
            title: "Session Expiring",
            width: 400,
            resizable: false,
            scrollable: false,
            adaptive: true,
            component: IdleTimeoutDialog,
            props: { remainingMs },
            okOnly: true,
            onComponentAction({ originalEvent }) {
                logInfo("openAppIdleDialog() :: openDialog(...).onComponentAction()", {
                    idleDialog_originalEvent_name: originalEvent?.name,
                    idleDialog_originalEvent_data: originalEvent?.data
                });
                if (originalEvent?.name !== "activity-check") return false;
                let lastActive = getBrowserLastActive();
                if(!originalEvent?.data?.timerExpired && browserTabLastActive.value === lastActive) return false;
                if(browserTabLastActive.value < lastActive) return continueSession();
                setBrowserLastActive(0);
                store.dispatch(AUTH_ACTIONS.LOGOUT, { redirectUrl: location.href });
            },
            onOk(e) {
                logInfo("openAppIdleDialog() :: openDialog(...).onOk()");
                return continueSession();
            }
        });
    }

    function closeAppIdleDialog() {
        if(!idleDialogActive.value) return;
        logInfo("closeAppIdleDialog()");
        closeDialog(idleDialogId.value);
        idleDialogId.value = null;
    }

    return {
        startIdleJs,
        isIdle
    };
}