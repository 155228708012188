import FocusElement from "./focus";
import FocusSelectAll from "./focus-select-all";
import AutoResizeTextarea from "./auto-resize-textarea";
import RqTooltip from "./rq-tooltip";

export default {
    install (app) {
        app.directive("focus", FocusElement);
        app.directive("focus-select-all", FocusSelectAll);
        app.directive("auto-resize", AutoResizeTextarea);
        app.directive("rq-tooltip", RqTooltip);
    }
}
