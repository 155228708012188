const ID_TOKEN_KEY = 'id_token';

let service = {
    getToken () {
        return window.localStorage.getItem(ID_TOKEN_KEY);
    },

    parseToken () {
        let token = this.getToken();
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        let user = JSON.parse(window.atob(base64));
        return user;
    },

    saveToken (token) {
        window.localStorage.setItem(ID_TOKEN_KEY, token);
    },

    destroyToken () {
        window.localStorage.removeItem(ID_TOKEN_KEY);
    }
};

export const JwtService = service;
