// globalDialogs.js
import { useRqDialog, useRqBusy, useRqToast } from "../plugins/composables";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { ORDER_ACTIONS, SET_PAGE } from "@/store/actions";
import { ORDER_MUTATIONS } from '@/store/mutations';
import { GlobalEventManager, GLOBAL_EVENTS } from "@/app.events";

export function useGlobalDialogs() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { openDialog } = useRqDialog();
    const { waitFor } = useRqBusy();
    const { toastError } = useRqToast();


    const GLOBAL_DIALOG_EVENT_SOURCE = "app-root::global-dialogs"
    function registerDialogEvents() {
        // GlobalEventManager.onPrint(GLOBAL_DIALOG_EVENT_SOURCE, openDocumentGenerationDialog);
        // GlobalEventManager.onEmail(GLOBAL_DIALOG_EVENT_SOURCE, showEmail);
        // GlobalEventManager.onNewOrder(GLOBAL_DIALOG_EVENT_SOURCE, newOrder);
        // GlobalEventManager.onNewSecondaryOrder(GLOBAL_DIALOG_EVENT_SOURCE, newSecondaryOrder);
        // GlobalEventManager.register(GLOBAL_DIALOG_EVENT_SOURCE, GLOBAL_EVENTS.SAVE_DOCUMENTS, openSaveDocumentsDialog);
        // GlobalEventManager.register(GLOBAL_DIALOG_EVENT_SOURCE, GLOBAL_EVENTS.FILE_NUMBER_PROMPT, openFileNumberDialog);
    }

    function unregisterDialogEvents() {
        GlobalEventManager.unregister(GLOBAL_DIALOG_EVENT_SOURCE);
    }

    return {
        registerDialogEvents,
        unregisterDialogEvents
    };
}